import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { apiCall } from "../../services/ApiCall";
import config from "../../config/config.json";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function AssignRequestModal({getRequestsList, isOpen, item, onRequestClose, }) {

  const [answer, setAnswer] = useState({
    araId: "",
    companyId: 0,
    address: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    recheckcycle: '12',
    userData: {
      fname: "",
      lname: "",
      phone: "",
      designation: "",
      email: "",
      role: ""
    }
  });

  const [dataArray, setDataArray] = useState([])

  const navigate = useNavigate();
  const [contacts, setContacts] = useState([answer.userData]);
  const [errors, setErrors] = useState({});

  const handleChange = (index, key, value) => {
    const updatedContacts = contacts.map((contact, i) =>
      i === index ? { ...contact, [key]: value } : contact
    );
    setContacts(updatedContacts);
  };


  const onSubmit = async () => {

    const updatedAnswer = { requestId: Number(item.id), siteAdminId: Number(dataId), siteManagerId: Number(pictureId) };
    const { isSuccess } = await apiCall(
      `${config.POST_LOGIN_API_URL}assignedSiteAdminAndSiteManager`,
      {},
      updatedAnswer,
      "POST"
    );
    if (isSuccess) {
      getRequestsList()

    }

  }

  const validate = () => {
    const newErrors = {};
    contacts.forEach((contact, index) => {
      const fname = contact.fname || "";
      const email = contact.email || "";
      const designation = contact.designation || "";

      if (!fname.trim()) {
        newErrors[`fname-${index}`] = "First Name is required";
      }
      if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
        newErrors[`email-${index}`] = "A valid Email is required";
      }
      if (!designation.trim()) {
        newErrors[`designation-${index}`] = "Designation is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addNewSiteAndContact = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const updatedAnswer = { userData: contacts, siteId: Number(item.siteId) };
    const { isSuccess } = await apiCall(
      `${config.POST_LOGIN_API_URL}addNewSitesContact`,
      {},
      updatedAnswer,
      "POST"
    );
    if (isSuccess) {
      handleTabSwitch("assignRequest")

    }
  };
  const getSitesContact = async () => {

    const updatedAnswer = { siteId: Number(item.siteId) };
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getSitesContact`,
      {},
      updatedAnswer,
      "GET"
    );
    if (isSuccess) {
      setDataArray(data.data.sitesUserData)
      // const firstSiteAdmin = data.data.sitesUserData.find(person => person.designation === 'Site Admin');

      // // Find the first object where the designation is 'Site Manager'
      // const firstSiteManager = data.data.sitesUserData.find(person => person.designation === 'Site Manager');

      // // Set the dataForm with the first Site Admin's data if found
      // if (firstSiteAdmin) {
      //   setDataForm({
      //     firstName: firstSiteAdmin.fname,
      //     email: firstSiteAdmin.email,
      //     phone: firstSiteAdmin.phone
      //   });
      //   setDataId(firstSiteAdmin.id)
      // }

      // // Set the pictureForm with the first Site Manager's data if found
      // if (firstSiteManager) {
      //   setPictureForm({
      //     firstName: firstSiteManager.fname,
      //     email: firstSiteManager.email,
      //     phone: firstSiteManager.phone
      //   });
      //   setPictureId(firstSiteManager.id)
      // }
    }
  };
  const modalStyles = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: "530px",
      padding: "0px",
      backgroundColor: "#eaf2fb",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const headerContainerStyle = {
    backgroundColor: "#0050A4",
    padding: "10px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  };

  const headerTextStyle = {
    color: "white",
    fontSize: "18px",
  };

  const closeIconStyle = {
    color: "white",
    cursor: "pointer",
    fontSize: "20px",
  };

  const bodyStyle = {
    color: "#0050A4",
    padding: "20px",
  };

  const formGroupStyle = {
    marginBottom: "20px",
    color: "#0050A4",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "5px",

    color: "#0050A4",
    fontSize: "16px",
    fontWeight: "600",
  };

  const inputRowStyle = {
    display: "flex",
    gap: "10px",
  };

  const inputStyle = {
    width: "100%",
    color: "#0050A4",
    flex: "1",
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #0050A4",
  };

  const buttonStyle = {
    backgroundColor: "#0050A4",
    color: "white",
    border: "none",
    padding: "5px 20px",
    cursor: "pointer",
    borderRadius: "5px",
    fontSize: "14px",
  };

  const addButtonStyle = {
    backgroundColor: "white",
    color: "#0050A4",
    fontWeight: '600',
    border: '1px solid #0050A4',
    padding: "10px 22px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
    marginBottom: "10px",
    marginRight: "10px",
  };

  const [dataForm, setDataForm] = useState({
    firstName: "",
    email: "",
    phone: "",
  });
  const [pictureForm, setPictureForm] = useState({
    firstName: "",
    email: "",
    phone: "",
  });



  const tabStyle = { cursor: 'pointer', padding: '10px 20px', color: "white", borderBottom: '2px solid transparent' };
  const activeTabStyle = { borderBottom: '2px solid #007bff', fontWeight: 'bold' };

  const [dataId, setDataId] = useState()
  const [pictureId, setPictureId] = useState();

  const [activeTab, setActiveTab] = useState('assignRequest');
  const handleSelectChange = (e) => {
    console.log(e.target.value); // Log the value to confirm
    const selectedPerson = JSON.parse(e.target.value); // Parse the JSON string back into an object
    console.log(selectedPerson); // This will now be the actual person object

    // Update the form fields with the selected person's data
    setDataForm({
      firstName: selectedPerson.fname,
      email: selectedPerson.email,
      phone: selectedPerson.phone
    });
    setDataId(selectedPerson.id)
  };

  const handleSelectChangeTwo = (e) => {
    console.log(e.target.value); // Log the value to confirm
    const selectedPerson = JSON.parse(e.target.value); // Parse the JSON string back into an object
    console.log(selectedPerson); // This will now be the actual person object

    // Update the form fields with the selected person's data
    setPictureForm({
      firstName: selectedPerson.fname,
      email: selectedPerson.email,
      phone: selectedPerson.phone
    });
    setPictureId(selectedPerson.id)
  };

  useEffect(() => {
    if (activeTab === "assignRequest") {
      getSitesContact()
    }
  }, [activeTab])

  // Switch between tabs
  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
    setPictureForm({
      firstName: "",
      email: "",
      phone: "",
    })
    setDataForm({
      firstName: "",
      email: "",
      phone: "",
    })
  };
  return (

    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={modalStyles}>
      <div>
        {/* Header */}
        <div style={headerContainerStyle}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            {/* Modal Title */}


            {/* Tabs in Header */}
            <div style={{ display: 'flex', gap: '10px' }}>
              <span
                style={activeTab === 'assignRequest' ? { ...tabStyle, ...activeTabStyle } : tabStyle}
                onClick={() => handleTabSwitch('assignRequest')}
              >
                Assign Request
              </span>
              <span
                style={activeTab === 'addContact' ? { ...tabStyle, ...activeTabStyle } : tabStyle}
                onClick={() => handleTabSwitch('addContact')}
              >
                Add Contact
              </span>
            </div>
          </div>

          {/* Close Icon */}
          <span style={closeIconStyle} onClick={onRequestClose}>
            &times;
          </span>
        </div>

        {/* Content for Assign Request */}
        {activeTab === 'assignRequest' && (
          <div style={bodyStyle}>
            {/* Form for Assign Person For Data Form */}
            <div style={formGroupStyle}>
              <label style={labelStyle}>Assign Person For Data Form</label>
              <select aria-placeholder="Please select a person" onChange={handleSelectChange} style={{ ...inputStyle, marginBottom: '10px' }}>
              <option value="" disabled selected hidden>Please select a person</option>
                {dataArray && dataArray.length > 0 && dataArray.map((person) =>
                  person.designation === 'Site Admin' ? (
                    <option key={person.id} value={JSON.stringify(person)}>
                      {person.name}
                    </option>
                  ) : null
                )}
              </select>
              <div style={inputRowStyle} className="mt-4">
                <div style={{ flex: '1' }}>
                  <label style={labelStyle}>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    style={inputStyle}
                    value={dataForm.firstName}
                    onChange={(e) => setDataForm({ ...dataForm, firstName: e.target.value })}
                  />
                </div>
                <div style={{ flex: '1' }}>
                  <label style={labelStyle}>Email</label>
                  <input
                    type="email"
                    name="email"
                    style={inputStyle}
                    value={dataForm.email}
                    onChange={(e) => setDataForm({ ...dataForm, email: e.target.value })}
                  />
                </div>
                <div style={{ flex: '1' }}>
                  <label style={labelStyle}>Phone</label>
                  <input
                    type="text"
                    name="phone"
                    style={inputStyle}
                    value={dataForm.phone}
                    onChange={(e) => setDataForm({ ...dataForm, phone: e.target.value })}
                  />
                </div>
              </div>
            </div>

            {/* Form for Assign Person For Picture Form */}
            <div style={formGroupStyle}>
              <label style={labelStyle}>Assign Person For Picture Form</label>
              <select onChange={handleSelectChangeTwo} style={{ ...inputStyle, marginBottom: '10px' }}>
              <option value="" disabled selected hidden>Please select a person</option>

                {dataArray && dataArray.length > 0 && dataArray.map((person) =>
                  person.designation === 'Site Manager' ? (
                    <option key={person.id} value={JSON.stringify(person)}>
                      {person.name}
                    </option>
                  ) : null
                )}
              </select>
              <div style={inputRowStyle} className="mt-4">
                <div style={{ flex: '1' }}>
                  <label style={labelStyle}>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    style={inputStyle}
                    value={pictureForm.firstName}
                    onChange={(e) => setPictureForm({ ...dataForm, firstName: e.target.value })}

                  />
                </div>
                <div style={{ flex: '1' }}>
                  <label style={labelStyle}>Email</label>
                  <input
                    type="email"
                    name="email"
                    style={inputStyle}
                    value={pictureForm.email}
                    onChange={(e) => setPictureForm({ ...dataForm, email: e.target.value })}

                  />
                </div>
                <div style={{ flex: '1' }}>
                  <label style={labelStyle}>Phone</label>
                  <input
                    type="text"
                    name="phone"
                    style={inputStyle}
                    value={pictureForm.phone}
                    onChange={(e) => setPictureForm({ ...dataForm, phone: e.target.value })}

                  />
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button style={buttonStyle} onClick={() => onSubmit()}>
                Submit
              </button>
            </div>
          </div>
        )}

        {/* Content for Add Contact */}
        {activeTab === 'addContact' && (
          <div className="add-site">

            {contacts.map((contact, index) => (
              <div key={index} className="contact-container">
                <div className="site-detail-h">
                  <div className="site-detail-left">Site contact</div>
                  <div className="site-detail-right">


                  </div>
                </div>
                <div className="site-contact">
                  <form onSubmit={addNewSiteAndContact}>
                    <div className="form-row">
                      <div className="form-group">
                        <span htmlFor={`fname-${index}`}>First Name</span>
                        <input
                          onChange={(e) => handleChange(index, "fname", e.target.value)}
                          value={contact.fname}
                          type="text"
                          id={`fname-${index}`}
                        />
                        {errors[`fname-${index}`] && <div className="error-message">{errors[`fname-${index}`]}</div>}
                      </div>
                      <div className="form-group">
                        <span htmlFor={`lname-${index}`}>Last Name</span>
                        <input
                          onChange={(e) => handleChange(index, "lname", e.target.value)}
                          value={contact.lname}
                          type="text"
                          id={`lname-${index}`}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group">
                        <span htmlFor={`email-${index}`}>Email</span>
                        <input
                          onChange={(e) => handleChange(index, "email", e.target.value)}
                          value={contact.email}
                          type="text"
                          id={`email-${index}`}
                        />
                        {errors[`email-${index}`] && <div className="error-message">{errors[`email-${index}`]}</div>}
                      </div>
                      <div className="form-group">
                        <span style={{ marginBottom: "2px" }} htmlFor={`phone-${index}`}>Phone</span>
                        <PhoneInput
                          country={'us'} // Restricts to the US phone number format
                          value={contact.phone}
                          onChange={(phone) => handleChange(index, "phone", phone)}
                          inputStyle={{
                            borderRadius: "8px"
                          }} // You can add your styles here
                        />
                      </div>
                      <div className="form-group">
                        <span htmlFor={`designation-${index}`}>Designation</span>
                        <select
                          onChange={(e) => handleChange(index, "designation", e.target.value)}
                          value={contact.designation}
                          id={`designation-${index}`}
                        >
                          <option value="">Select Designation</option>
                          <option value="Site Admin">Site Admin</option>
                          <option value="Site Manager">Site Manager</option>
                        </select>
                        {errors[`designation-${index}`] && <div className="error-message">{errors[`designation-${index}`]}</div>}
                      </div>
                    </div>
                    <div className="form-group-foot">
                      <span className="text-start">

                      </span>
                      <span className="text-end">
                        <button type="submit" className="next-btn ps-4 pe-4">
                          Save
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default AssignRequestModal;






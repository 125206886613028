import React, { useEffect, useState } from 'react';
import mysiteInspectionLogo from './img/mysiteInspectionLogo.png';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { apiCall } from "../../services/ApiCall";
import config from "../../config/config.json";
import DataFormTab from './DataFormTab';
import { generatePDF } from './ViewPdfReport';

const VieInspectionReport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [uploadedImages, setUploadedImages] = useState({});
  const [modules, setModules] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [requestData, setRequestData] = useState();

  const syncToZoho = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}syncToZoho`,
        {},
        { requestId: location.state?.requestId },
        "POST"
      );
      if (isSuccess) {
      }

    } catch (error) {
      console.error("Failed to fetch data:", error);

    }
  };

  const approveRequest = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}approveRequest`,
        {},
        { requestId: location.state?.requestId },
        "POST"
      );
      if (isSuccess) {
      }

    } catch (error) {
      console.error("Failed to fetch data:", error);

    }
  };




  const getRequestUserData = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestUserData`,
        {},
        { requestId: location.state?.requestId },
        "GET"
      );
      if (isSuccess) {
        setRequestData(data.data);
      }

    } catch (error) {
      console.error("Failed to fetch data:", error);

    }
  };

  const getRequestPictures = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestPictures`,
        {},
        { requestId: location.state?.requestId },
        "GET"
      );
      if (isSuccess && data.data.length) {
        setUploadedImages(data.data[0]);
      }

    } catch (error) {
      console.error("Failed to fetch data:", error);

    }
  };

  const getDataFormQuestionList = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getDataFormQuestionList`,
        {},
        { type: "ALL" },
        "GET"
      );

      if (isSuccess && Array.isArray(data.data)) {
        setModules(data.data);
      } else {
        console.error("API response is not an array:", data);
        setModules([]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setModules([]);
    }
  };
  const getRequestDataFormAnswer = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestDataFormAnswer`,
        {},
        { requestId: location.state?.requestId },
        "GET"
      );
      if (isSuccess) {
        setAnswers(data.data);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getDataFormQuestionList();
      getRequestUserData();
      getRequestPictures();
      getRequestDataFormAnswer();
    }
  }, [navigate]);

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <div style={styles.headerLeft}>
          <NavLink to="/request-form" state={{ requestId: location.state?.requestId }}>
            <button style={styles.headerButton}>Edit Request</button>
          </NavLink>

          <button style={styles.headerButton} onClick={syncToZoho}>Sync To Zoho</button>
          <button style={styles.headerButton} onClick={approveRequest}>Approve</button>


        </div>
      </header>
      <div className='header' style={{ backgroundColor: '#DCEDFF', padding: '30px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }} >
        <div>
          <img
            src={mysiteInspectionLogo} alt="MySiteInspection.com"
            style={styles.logo}
          /></div>
        <div>
          <span style={{ marginRight: '1em' }}>Support@mysiteinspection.com</span>
          <strong style={{ marginRight: '1em' }}>401.463.0200</strong>
        </div>
      </div>
      <main style={styles.reportContent}>
        <h1 style={styles.title}>Site Inspection Compliance Report</h1>
        <div style={styles.reportDetails}>
          <div style={styles.reportItem}>
            <div style={{ fontSize: '12px' }}>Company Name</div>
            <strong style={{ fontSize: '18px' }}>{requestData?.companyName}</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{ fontSize: '12px' }}>Contact Name</div>
            <strong style={{ fontSize: '18px' }}>{requestData?.contactName}</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{ fontSize: '12px' }}>Contact Number</div>
            <strong style={{ fontSize: '18px' }}>{requestData?.contactPhone}</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{ fontSize: '12px' }}>Contact Email</div>
            <strong style={{ fontSize: '18px' }}>{requestData?.contactEmail}</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{ fontSize: '12px' }}>Inspection Due Date</div>
            <strong style={{ fontSize: '18px' }}>
              {new Date(requestData?.scheduledDate).toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
              })}
            </strong>          </div>
          <div style={styles.reportItem}>
            <div style={{ fontSize: '12px' }}>Approved Date</div>
            <strong style={{ fontSize: '18px' }}>   {new Date(requestData?.approvedDate).toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            })}</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{ fontSize: '12px' }}>Reference</div>
            <strong style={{ fontSize: '18px' }}>{requestData?.araId}</strong>
          </div>

          <div style={styles.reportItem}>
            <div style={{ fontSize: '12px' }}>Next Renewal Date</div>
            <strong style={{ fontSize: '18px' }}>   {new Date(requestData?.scheduledDate).toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            })}</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{ fontSize: '12px' }}>Site Admin Contact</div>
            <strong style={{ fontSize: '18px' }}>{requestData?.siteAdminName}</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{ fontSize: '12px' }}>Admin Contact Phone</div>
            <strong style={{ fontSize: '18px' }}>{requestData?.siteAdminPhone}</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{ fontSize: '12px' }}>Admin Contact Email</div>
            <strong style={{ fontSize: '18px' }}>{requestData?.siteAdminEmail}</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{ fontSize: '12px' }}>Site Manager Contact</div>
            <strong style={{ fontSize: '18px' }}>{requestData?.siteAdminName}</strong>
          </div>

          <div style={styles.reportItem}>
            <div style={{ fontSize: '12px' }}>Site Manager Email</div>
            <strong style={{ fontSize: '18px' }}>{requestData?.siteManagerEmail}</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{ fontSize: '12px' }}>Site Manager Phone</div>
            <strong style={{ fontSize: '18px' }}>{requestData?.siteManagerPhone}</strong>
          </div>
          <div style={styles.reportItem}>
            <div style={{ fontSize: '12px' }}>Address</div>
            <strong style={{ fontSize: '18px' }}>{requestData?.siteAddress + ' ' + requestData?.siteCity + ' ' + requestData?.siteState}</strong>
          </div>
        </div>
      </main>
      {modules.map((module, index) => (
        <DataFormTab module={module} answer={answers[module.moduleTitle]} />
      ))}
      <div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1em'
  },
  headerLeft: {
    display: 'flex',
    gap: '10px',
  },
  headerButton: {
    backgroundColor: '#0050A4',
    color: 'white',
    border: 'none',
    marginRight: '2em',
    padding: '10px 15px',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  logo: {
    height: '40px',
  },
  contactInfo: {
    textAlign: 'right',
  },
  reportContent: {
    paddingLeft: '10px',
    marginTop: '2em'
  },
  title: {
    fontSize: '30px',
    color: '#0050A4',
    marginBottom: '40px',
  },
  reportDetails: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    paddingTop: '0px',
    padding: '10px',
    gap: '30px',
    color: '#0050A4',
  },
  reportItem: {
    marginBottom: '10px',
  },
};

export default VieInspectionReport;

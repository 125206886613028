import React, { useEffect, useState } from 'react';
import { apiCall } from '../../../services/ApiCall.js';
import Loader from './Loader.js';
import config from "../../../config/config.json";
import { useNavigate } from 'react-router-dom';

const CompanyHistory = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const [searchQuery, setSearchQuery] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: value,
    });
  };

  const handleSaveClick = () => {
    const newData = [...data];
    newData[editRowIndex] = editFormData;
    setData(newData);
    setEditRowIndex(null);
  };

  const getCompanyHistoryList = async () => {
    setLoading(true);
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getCompanyHistoryList`,
      {},
      {},
      "GET"
    );
    setLoading(false);
    if (isSuccess) {
      setData(data?.data?.reverse());
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getCompanyHistoryList();
  }, []);

  const filteredData = data.filter(item => {
    const fullAddress = `${item?.address} ${item?.city} ${item?.state}`.toLowerCase();
    const searchLower = searchQuery.toLowerCase();
    return (
      item?.companyName.toLowerCase().includes(searchLower) ||
      fullAddress.includes(searchLower) ||
      item?.name.toLowerCase().includes(searchLower) ||
      item?.activity.toLowerCase().includes(searchLower)
    );
  });

  console.log(filteredData)

  if (loading) {
    return <Loader />;
  }

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12 || 12; // Convert to 12-hour format
    hours = String(hours).padStart(2, '0'); // Pad hours if needed
  
    return `${month}-${day}-${year} ${hours}:${minutes} ${ampm}`;
  };
  

  return (
    <div style={styles.container}>
      <div style={styles.header} className='mb-5'>
        <div style={styles.heading}>Company History</div>
        <div className="search-box col-lg-8 text-center">
          <input
            type="text"
            className="form-control"
            placeholder="Search by Company Name, Address, Activity, or Name..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Company Name</th>
            <th style={styles.th}>Address</th>
            <th style={styles.th}>Activity By</th>
            <th style={styles.th}>Activity</th>
            <th style={styles.th}>Date & Time</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={index}>
              {editRowIndex === index ? (
                <>
                  <td style={styles.td}>
                    <input
                      style={styles.input}
                      name="companyName"
                      value={editFormData.companyName}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={styles.td}>
                    <input
                      style={styles.input}
                      name="address"
                      value={editFormData.address}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={styles.td}>
                    <input
                      style={styles.input}
                      name="activityBy"
                      value={editFormData.activityBy}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={styles.td}>
                    <input
                      style={styles.input}
                      name="activity"
                      value={editFormData.activity}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={styles.td}>
                    <input
                      style={styles.input}
                      name="date"
                      value={editFormData.date}
                      onChange={handleInputChange}
                    />
                    <input
                      style={styles.input}
                      name="time"
                      value={editFormData.time}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td style={styles.td}>
                    <button style={styles.saveButton} onClick={handleSaveClick}>Save</button>
                  </td>
                </>
              ) : (
                <>
                  <td style={styles.td}>{item?.companyName}</td>
                  <td style={styles.td}>{`${item?.address} ${item?.city} ${item?.state}`}</td>
                  <td style={styles.td}>{item?.name}</td>
                  <td style={styles.td}>{item?.activity}</td>
                  <td style={styles.td}>{formatDateTime(item?.createdAt)}</td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompanyHistory;

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    margin: '20px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  heading: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#0050A4',
  },
  exportButton: {
    backgroundColor: 'white',
    color: '#0050A4',
    fontSize: '16px',
    padding: '10px 20px',
    border: '1px solid #0050A4',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    backgroundColor: '#0050A4',
    color: '#ffffff',
    padding: '12px',
    width: '22%',
    fontSize: '18px',
    textAlign: 'left',
  },
  td: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
    color: '#0050A4',
    fontSize: '14px',
    fontWeight: '400',
  },
  actionButton: {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  icon: {
    width: '100%',
    height: '100%',
  },
  input: {
    width: '100%',
    padding: '8px',
    fontSize: '14px',
    fontWeight: '400',
  },
  saveButton: {
    backgroundColor: '#0050A4',
    color: '#ffffff',
    fontSize: '16px',
    padding: '8px 12px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};
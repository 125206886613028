import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'; 
import './ProgressCards.css';
import newRequestIcon from './img/newRequestIcon.png';
import inProgressIcon from './img/inProgressIcon.png';
import toBeApproved from './img/toBeApprovedIcon.png';
import approvedRequestIcon from './img/approvedRequest.png';
import redoIcon from './img/redoIcon.png';
import upcomingRequestIcon from './img/upcomingRequest.png';
import { apiCall } from "../../services/ApiCall.js";
import config from "../../config/config.json";

const ProgressCard = ({ icon, title, value }) => (
  <div className="progress-card">
    <div className="progress-card-content">
      <span className="progress-card-icon">{icon}</span>
      <div className="progress-card-info">
        <span className="progress-card-title">{title}</span>
        <span className="progress-card-value">{value}</span>
      </div>
    </div>
  </div>
);

const ProgressCards = () => {
const navigate = useNavigate();
const [requestData, setRequestData] = useState({});

  const getRequestData = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getRequestGraphData`,
      {},
      {},
      "GET"
    );

    if (isSuccess) {
      const requestDatas = data?.data.reduce((acc, request) => {
        const { status, id, createdAt } = request;
        if (!acc[status]) {
          acc[status] = [];
        }
        acc[status].push({ id, createdAt });
        return acc;
      }, {});
      setRequestData(requestDatas);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getRequestData();
  }, []);

  const cards = [
    { 
      icon: <div style={{backgroundColor:'rgba(226, 234, 253, 1)',border:'1px solid rgba(226, 234, 253, 1)',borderRadius:'50%',padding:'5px',marginLeft:'8px',marginRight:'0px',paddingBottom:'10px'}} >
        <img className='newRequestIcon w-75 h-50' src={newRequestIcon} alt=''/>
      </div>, 
      title: 'New Requests', 
      value: requestData && requestData[0]? requestData[0].length : 0, 
      link: '/new-Request' 
    },

    { 
      icon: <div style={{backgroundColor:'rgba(226, 234, 253, 1)',border:'1px solid rgba(226, 234, 253, 1)',borderRadius:'50%',paddingLeft:'4px' ,marginLeft:'5px',paddingTop:'5px' ,paddingRight:'0px',paddingBottom:'6px'}} > 
        <img className='w-75 h-50' src={inProgressIcon} alt=''/>
      </div>, 
      title: 'In-Progress Request', 
      value: requestData && requestData[1] ? requestData[1].length: 0, 
      link: '/in-progress'
    },

    { 
      icon: <div style={{backgroundColor:'rgba(226, 234, 253, 1)',border:'1px solid rgba(226, 234, 253, 1)',borderRadius:'50%',paddingLeft:'5px' ,marginLeft:'5px',paddingTop:'5px' ,paddingRight:'5px',paddingBottom:'6px'}}>
        <img src={toBeApproved} className='w-75 h-50' alt=''/>
      </div>, 
      title: 'To-Be-Approved Requests', 
      value: requestData && requestData[2]? requestData[2].length : 0,
      link: '/to-be-approved'
    },

    { 
      icon: <div style={{backgroundColor:'rgba(226, 234, 253, 1)',border:'1px solid rgba(226, 234, 253, 1)',borderRadius:'50%',paddingLeft:'6px' ,marginLeft:'5px',paddingTop:'5px' ,paddingRight:'5px',paddingBottom:'8px'}}>
        <img src={redoIcon} className='w-75 h-50' alt=''/>
      </div>, 
      title: 'Redo Requests', 
      value: requestData && requestData[7]? requestData[7].length: 0,
      link: '/redo',
    },

    { 
      icon: <div style={{backgroundColor:'rgba(226, 234, 253, 1)',border:'1px solid rgba(226, 234, 253, 1)',borderRadius:'50%',paddingLeft:'6px' ,marginLeft:'5px',paddingTop:'5px' ,paddingRight:'5px',paddingBottom:'8px'}}> 
        <img src={approvedRequestIcon} className='w-75 h-50' alt=''/>
      </div>, 
      title: 'Approved Requests', 
      value: requestData && requestData[4]? requestData[4].length: 0,
      link:'/approved-Request',
    },

    { 
      icon: <div style={{backgroundColor:'rgba(226, 234, 253, 1)',border:'1px solid rgba(226, 234, 253, 1)',borderRadius:'50%',padding:'5px',marginLeft:'8px',marginRight:'0px',paddingBottom:'10px'}}>
        <img src={upcomingRequestIcon} className='w-75 h-50' alt=''/>
      </div>, 
      title: 'Archive Requests', 
      value: requestData && requestData[9] ? requestData[9].length : 0,
      link:'/upcoming-request'
    },
  ];

  return (
    <>
      <div className='Progress'>Progress</div>
      <div className="progress-cards-container">
        {cards.map((card, index) => (
          card.link ? (
            <NavLink key={index} style={{ textDecoration: 'none' }} to={card.link}>
              <ProgressCard {...card} />
            </NavLink>
          ) : (
            <ProgressCard key={index} {...card} />
          )
        ))}
      </div>
    </>
  );
};

export default ProgressCards;
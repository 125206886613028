import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./main.css";
import EditSite from "./EditSite";
import viewIcon from "../img/viewIcon.png";
import editIcon from "../img/editIcon.png";
import archiveIcon from "../img/archiveIcon.png";
import { apiCall } from "../../../services/ApiCall";
import config from "../../../config/config.json";
import { Modal, Button } from "react-bootstrap";
import Loader from "../../CompanyFolder/CompanyList/Loader";
import ViewSiteDetailsPage from "./ViewPage";

const Main = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [deletedMode, setDeletedMode] = useState("DELETE");
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [deleteId, setDeleteId] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [mode, setMode] = useState(null);
  const [editData, setEditData] = useState(null);

  const handleRoutingChange = (newPage, data) => {
    if (newPage === "addSite") {
      navigate("/add-Site");
    } else if (newPage === "viewRequest") {
      navigate(`/all-request/view/${data.id}`);
    } else if (newPage === "view") {
      setViewData(data);
      setShowDetails(true);
      setMode('VIEW');
    } else if (newPage === "edit") {
      setMode('EDIT');
      setEditData(data);
      setShowDetails(true);
    }
  };
  const handleActionIconClick = (id, mode) => {
    setDeleteId(id);
    setShowDeleteModal(true);
    setDeletedMode(mode);
  };
  const handleAction = () => {
    actionSite(deleteId);
    setShowDeleteModal(false);
  };
  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedData = [...data].sort((a, b) => {
      if (key === "#") {
        return direction === "ascending"
          ? a.sequence - b.sequence
          : b.sequence - a.sequence;
      } else {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      }
    });

    setSortConfig({ key, direction });
    setData(sortedData);
  };
  const actionSite = async (id) => {
    const endpoint = deletedMode === "DELETE" ? "deleteSite" : "archiveSite";
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}${endpoint}`,
      {},
      { siteId: id },
      "POST"
    );
    if (isSuccess) {
      getSitesList();
    }
  };
  const numberedData = data.map((row, index) => ({
    ...row,
    sequence: index + 1,
  }));

  const filteredData = numberedData.filter((row) => {
    return [
      "id",
      "araId",
      "companyName",
      "name",
      "street",
      "city",
      "state",
      "zipcode",
      "address",
    ].some((key) =>
      row[key]?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
  });


  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleEntriesChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPagination = () => {
    const visiblePages = 3;
    const halfVisible = Math.floor(visiblePages / 2);
    let startPage = Math.max(currentPage - halfVisible, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (endPage - startPage + 1 < visiblePages) {
      startPage = Math.max(endPage - visiblePages + 1, 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li
          key={i}
          className={currentPage === i ? "active" : ""}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </li>
      );
    }

    return pages;
  };

  const renderSortIcons = (key) => {
    if (sortConfig.key !== key) {
      return "▲▼";
    }
    return sortConfig.direction === "ascending" ? "▲" : "▼";
  };

  const getSitesList = async () => {
    setLoading(true);
    const match = location.pathname.match(/\/site-list\/view\/(\d+)/);
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getSitesList`,
      {},
      { companyId: match && match[1] },
      "GET"
    );
    setLoading(false);
    if (isSuccess) {
      setData(data?.data?.reverse());
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getSitesList();
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else if(!showDetails && mode === 'EDIT') {
      getSitesList();
    }
  }, [showDetails]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container mt-1 ">
      <div className="controls row">
        <div className="show-entries col-lg-2">
          <label>Show </label>
          <select
            value={entriesPerPage}
            className="form-control"
            onChange={handleEntriesChange}
          >
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={250}>250</option>
            <option value={500}>500</option>
          </select>
          <label> entries</label>
        </div>
        <div className="search-box col-lg-8 text-centre  ">
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="col-lg-2 text-end">
          <button
            className="add-site-button btn "
            style={{
              backgroundColor: "#F7921E",
              color: "white",
              fontSize: "12px",
              padding: "10px 22px",
            }}
            onClick={() => handleRoutingChange("addSite")}
          >
            + Add Site
          </button>
        </div>
      </div>

      <div className="pagination justify-content-end">
        <ul>
          <li
            onClick={handlePreviousPage}
            className={currentPage === 1 ? "disabled" : ""}
          >
            Previous
          </li>
          {renderPagination()}
          <li
            onClick={handleNextPage}
            className={currentPage === totalPages ? "disabled" : ""}
          >
            Next
          </li>
        </ul>
      </div>

      <div
        className="mt-5"
        style={{ border: "1px solid black", borderRadius: "10px" }}
      >
        <table className="company-table ">
          <thead className="">
            <tr>
              <th style={{ width: "10%" }} onClick={() => sortData("id")}>
                ID {renderSortIcons("id")}
              </th>

              <th style={{ width: "15%" }} onClick={() => sortData("id")}>
                ARA ID {renderSortIcons("id")}
              </th>
              <th style={{ width: "15%" }} onClick={() => sortData("company")}>
                Company {renderSortIcons("company")}
              </th>
              <th style={{ width: "16%" }} onClick={() => sortData("address")}>
                Address {renderSortIcons("address")}
              </th>

              <th style={{ width: "12%" }} onClick={() => sortData("city")}>
                City {renderSortIcons("city")}
              </th>
              <th
                className="text-center"
                style={{ width: "10%" }}
                onClick={() => sortData("state")}
              >
                State {renderSortIcons("state")}
              </th>
           
              <th className="text-center" style={{ width: "20%" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {currentEntries.map((row, index) => (
              <tr key={index}>
                <td>{row?.id}</td>
                <td>{row?.araId}</td>
                <td>{row?.companyName}</td>
                <td>{row?.address}</td>

                <td>{row?.city}</td>
                <td className="text-center">{row?.state}</td>
                <td>
                  <img
                    className="mx-2 cursor-pointer"
                    src={viewIcon}
                    alt="View"
                    title="View"
                    onClick={() => handleRoutingChange("view", row)}
                  />
                  <img
                    className="mx-2 cursor-pointer"
                    src={editIcon}
                    alt="Edit"
                    title="Edit"
                    onClick={() => handleRoutingChange("edit", row)}
                  />
                  
                  <img
                    className="mx-2 cursor-pointer"
                    src={archiveIcon}
                    alt="Archive"
                    title="Archive"
                    onClick={() => handleActionIconClick(row.id, "ARCHIVE")}
                  />
                  <button
                    className="view-request-button btn btn-link p-2 ms-1"
                    style={{
                      border: "1px solid #0050A4",
                      textDecoration: "none",
                      backgroundColor: "#0050A4",
                      color: "white",
                      fontSize: "12px",
                    }}
                    onClick={() => handleRoutingChange("viewRequest", row)}
                  >
                    View Request
                  </button>
                </td>
           
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <ul>
          <li
            onClick={handlePreviousPage}
            className={currentPage === 1 ? "disabled" : ""}
          >
            Previous
          </li>
          {renderPagination()}
          <li
            onClick={handleNextPage}
            className={currentPage === totalPages ? "disabled" : ""}
          >
            Next
          </li>
        </ul>
      </div>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Confirm {deletedMode === "DELETE" ? "Delete" : "Archive"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to{" "}
          {deletedMode === "DELETE" ? "Delete" : "Archive"} this entry?
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <Button
            variant="secondary"
            style={{
              color: "#0050A4",
              border: "2px solid #0050A4",
              backgroundColor: "white",
              fontWeight: "bold",
            }}
            onClick={() => setShowDeleteModal(false)}
          >
            Close
          </Button>
          <Button
            variant="danger"
            style={{
              color: "white",
              backgroundColor: "#0050A4",
              border: "1px solid #0050A4",
            }}
            onClick={handleAction}
          >
            {deletedMode === "DELETE" ? "Delete" : "Archive"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDetails} onHide={() => setShowDetails(false)}   size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:"32px",color:"#0050A4"}}>{mode === 'VIEW' ?'Site Detail':  'Edit Site'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{mode === 'VIEW' ? <ViewSiteDetailsPage viewData={viewData} />:  <EditSite editData={editData} setShowDetails = {setShowDetails}/>}</Modal.Body>
        <Modal.Footer>
          <Button style={{backgroundColor:"#0050A4"}} onClick={() => setShowDetails(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Main;

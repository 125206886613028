import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import uploadIcon from "../TabForm/IMg/uploadIcon.png";
import { apiCall } from '../../services/ApiCall';
import config from "../../config/config.json";
import { useLocation } from 'react-router-dom';
import { Col, Row, Table } from 'react-bootstrap';
import './style.css'

const PDF = ({ module, answer, requestData, handleChange, index }) => {
  console.log(index)
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [uploadedImages, setUploadedImages] = useState({});
  const [explanations, setExplanations] = useState({});


  const toggleForm = () => {
    setIsOpen(!isOpen);
  };

  const getRequestPictures = async () => {
    console.log(location.state?.requestId)
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestPictures`,
        {},
        { requestId: location.state?.requestId },
        "GET"
      );
      if (isSuccess && data.data.length) {
        const tmpData = data.data[0]
        setUploadedImages(tmpData);
        for (let i = 1; i <= 19; i++) {
          const key = `p${i}a`;
          if (tmpData[key] !== null) {
            explanations[key] = true;
            explanations[`${key}value`] = tmpData[key];
          }
        }
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };
  useEffect(() => {
    getRequestPictures();

  }, [location.state?.requestId]);
  const getAnswerByKey = (answerKey) => {
    const result = answer && answer.find((item) => item.answerKey === answerKey);
    return result ? result.answer : '';
  };

  const styles = {
    formHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#e7f3ff',
      padding: '10px',
      borderRadius: '5px',
      marginBottom: '10px'
    },
    h2: {
      margin: 0,
      fontSize: '24px',
      color: 'black',
      fontWeight: '600',
    },
    toggleButton: {
      backgroundColor: 'transparent',
      border: 'none',
      borderRadius: '8px',
      fontSize: '18px',
      cursor: 'pointer',
    },
    formContent: {
      padding: '35px',
      borderRadius: '10px',
      border: 'none',
      paddingTop:"10px"

    },
    formGroup: {
      marginBottom: '20px'
    },
    label: {
      display: 'block',
      fontWeight: 'bold',
      marginBottom: '8px',
      fontSize: '16px'
    },
    inputRadioCheckbox: {
      width: '5%',
      marginRight: '8px',
    },
  };

  return (
    <div style={{ marginBottom: "50px", }}>

      {index === 0 ? (
        <div style={{ margin: '20px', border:"1px solid black", padding:"3px" }}>
          <div style={{ width: "100%", background: "lightgrey", display: "flex", justifyContent: "center", marginBottom: "10px", alignItems: "center", height: "6vh" }}>
            <div style={{ fontSize: "20px", fontWeight: 600 }}>Inspection ID: {requestData.araId}</div>


          </div>
          <Table bordered>
          <tbody>
  <tr>
    <td style={{ width: '20%' }}><strong>COMPANY NAME:</strong></td>
    <td>{requestData.companyName}</td>
    <td style={{ width: '20%' }}><strong>CONTACT NAME:</strong></td>
    <td>{requestData.contactName}</td>
  </tr>
  <tr>
    <td style={{ width: '20%' }}><strong>CONTACT PHONE:</strong></td>
    <td>{requestData.contactPhone !== 'undefined' ? requestData.contactPhone : 'N/A'}</td>
    <td style={{ width: '20%' }}><strong>CONTACT EMAIL:</strong></td>
    <td>{requestData.contactEmail}</td>
  </tr>
  <tr>
    <td style={{ width: '20%' }}><strong>INSPECTION DUE DATE:</strong></td>
    <td>{new Date(requestData.scheduledDate).toLocaleDateString()}</td>
    <td style={{ width: '20%' }}><strong>APPROVED DATE:</strong></td>
    <td>{requestData.approvedDate ? new Date(requestData.approvedDate).toLocaleDateString() : 'N/A'}</td>
  </tr>
  <tr>
    <td style={{ width: '20%' }}><strong>REFERENCE:</strong></td>
    <td>{requestData.araId}</td>
    <td style={{ width: '20%' }}><strong>INSPECTOR:</strong></td>
    <td>{requestData.siteAdminName}</td>
  </tr>
  <tr>
    <td style={{ width: '20%' }}><strong>ADDRESS:</strong></td>
    <td>{`${requestData.siteAddress}, ${requestData.siteCity}, ${requestData.siteState} ${requestData.siteZipcode}`}</td>
    <td style={{ width: '20%' }}><strong>NEXT RENEWAL DATE:</strong></td>
    <td>{requestData.scheduledDate ? new Date(requestData.scheduledDate).toLocaleDateString() : 'N/A'}</td>
  </tr>
  <tr>
    <td style={{ width: '20%' }}><strong>SITE ADMIN CONTACT:</strong></td>
    <td>{requestData.siteAdminName}</td>
    <td style={{ width: '20%' }}><strong>SITE MANAGER CONTACT:</strong></td>
    <td>{requestData.siteManagerName}</td>
  </tr>
  <tr>
    <td style={{ width: '20%' }}><strong>ADMIN EMAIL:</strong></td>
    <td>{requestData.siteAdminEmail}</td>
    <td style={{ width: '20%' }}><strong>MANAGER EMAIL:</strong></td>
    <td>{requestData.siteManagerEmail}</td>
  </tr>
  <tr>
    <td style={{ width: '20%' }}><strong>ADMIN PHONE:</strong></td>
    <td>{requestData.siteAdminPhone !== 'undefined' ? requestData.siteAdminPhone : 'N/A'}</td>
    <td style={{ width: '20%' }}><strong>MANAGER PHONE:</strong></td>
    <td>{requestData.siteManagerPhone !== 'undefined' ? requestData.siteManagerPhone : 'N/A'}</td>
  </tr>
</tbody>

          </Table>
        </div>
      ) : null}





      <div style={{ marginBottom: "10px", marginLeft: "1%" }}>
        <h2 style={styles.h2}>{module.moduleTitle}</h2>
      </div>

      <div style={styles.formContent}>
        {module && module.questions.map((question) => (
          <Row className="g-5" style={{ marginBottom: '10px', display: "flex", justifyContent: "space-between" }}>


            <Col md={6} style={{ border: "1px solid black" }}>
              <Label style={{ color: "black", fontSize: "15px", fontWeight: 600 }}>
                {`${question?.reportId}: ${question?.questionTitle || question?.title}`}
              </Label>
            </Col>





            <Col md={6} style={{ border: "1px solid black" }}>
              {
                question?.reportId && question?.reportId.includes('A-PHOTO') ? (
                  explanations[question.reportId.split(".")[0].toLowerCase() + "a"] ? (
                    <div className="mt-3">
                      <label className="ms-2" style={{ fontSize: "14px", color: "black" }}>
                        Unable to take photo, please explain
                      </label>
                      {explanations[question.reportId.split(".")[0].toLowerCase() + "a"] && (
                        <Input
                          className="mt-2"
                          style={{ border: "none" }}
                          placeholder="Please explain why you were unable to take a photo..."
                          value={explanations[question.reportId.split(".")[0].toLowerCase() + "a" + "value"] || ""}
                        />
                      )}
                    </div>
                  ) : (
                    <img
                      src={uploadedImages[question.reportId.split(".")[0].toLowerCase()] || uploadIcon}
                      alt="placeholder"
                      className="img-fluid"
                      style={{
                        background: "none",
                        padding: "20px",
                        height: "30vh",
                        width: "20vw",
                      }}
                    />
                  )
                ) : null
              }
              {module.moduleId === 1 ? (
                <>
                  <div>Company Name : {requestData?.companyName}</div>
                  <div>Site Address : {`${requestData?.siteAddress} ${requestData?.siteCity} ${requestData?.siteState}`}</div>
                </>
              ) : question.subQuestions && question.subQuestions.length === 0 ? (
                <Input
                  style={{
                    border: "none",
                    color: "black",
                  }}
                  className="me-4"
                  type="text"
                  name={`question-${question.id}`}
                  value={getAnswerByKey(question.reportId)}
                  onChange={(e) => handleChange(e, question.reportId, "text")}
                />
              ) : (
                question.subQuestions && question.subQuestions.map((subQuestion) => (
                  <div key={subQuestion.id}>
                    <FormGroup>
                      {subQuestion.optionType === "text" && (
                        <>
                          <Label>
                            <div style={{ color: "black", fontSize: "20px" }} className="mt-4">
                              {subQuestion.option}
                            </div>
                            <Input
                              style={{
                                border: "none",
                                color: "black",
                                width: "300px",
                              }}
                              className="mt-2"
                              type="text"
                              name={`subQuestion-${subQuestion.id}`}
                              value={getAnswerByKey(subQuestion.answerKey)}
                              onChange={(e) => handleChange(e, subQuestion.answerKey, "text")}
                            />
                          </Label>
                        </>
                      )}

                      {subQuestion.optionType === "checkbox" && (
                        <>
                          <Label style={{ display: "flex", marginTop: "10px" }}>
                            <Input
                              type="checkbox"
                              name={`subQuestion-${subQuestion.id}`}
                              style={{ background: "white", borderColor: "black" }}
                              checked={getAnswerByKey(question.reportId)?.split(",").includes(subQuestion.option.trim())}
                              onChange={(e) => handleChange(e, question.reportId, "checkbox", subQuestion.option)}
                            />
                            <span style={{ fontSize: "13px", color: "black", marginLeft: "10px" }}>
                              {subQuestion.option}
                            </span>
                          </Label>
                          {subQuestion.details && subQuestion.details.map((detail) => (
                            <FormGroup key={detail.id}>
                              {getAnswerByKey(question.reportId)?.split(",").includes(subQuestion.option.trim()) && (
                                <>
                                  <Label>
                                    <div style={{ color: "black", fontSize: "14px" }} className="ms-4">
                                      {detail.option}
                                    </div>
                                    <Input
                                      style={{
                                        border: "none",
                                        color: "black",
                                        width: "300px",
                                      }}
                                      className="mt-2 ms-4"
                                      type="text"
                                      name={`subQuestion-${detail.id}`}
                                      value={getAnswerByKey(detail.answerKey)}
                                      onChange={(e) => handleChange(e, detail.answerKey, "text")}
                                    />
                                  </Label>
                                </>
                              )}
                            </FormGroup>
                          ))}
                        </>
                      )}

                      {subQuestion.optionType === "radio" && (
                        <>
                          <Label style={{ display: "flex", marginTop: "10px" }}>
                            <Input
                              type="radio"
                              name={`subQuestion-${subQuestion.id}`}
                              value={subQuestion.option}
                              checked={getAnswerByKey(question.reportId) === subQuestion.option}
                              onChange={(e) => handleChange(e, question.reportId, "radio")}
                            />
                            <span style={{ fontSize: "13px", color: "black", marginLeft: "10px" }}>
                              {subQuestion.option}
                            </span>
                          </Label>
                          {subQuestion.details && subQuestion.details.map((detail) => (
                            <FormGroup key={detail.id}>
                              {getAnswerByKey(question.reportId) === subQuestion.option && (
                                <>
                                  <Label>
                                    <div style={{ color: "black", fontSize: "14px" }} className="ms-4">
                                      {detail.option}
                                    </div>
                                    <Input
                                      style={{
                                        border: "none",
                                        color: "black",
                                        width: "300px",
                                      }}
                                      className="mt-2 ms-4"
                                      type="text"
                                      name={`subQuestion-${detail.id}`}
                                      value={getAnswerByKey(detail.answerKey)}
                                      onChange={(e) => handleChange(e, detail.answerKey, "text")}
                                    />
                                  </Label>
                                </>
                              )}
                            </FormGroup>
                          ))}
                        </>
                      )}
                    </FormGroup>
                  </div>
                ))
              )}
            </Col>




          </Row>
        ))}
      </div>
    </div>
  );
};

export default PDF;


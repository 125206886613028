import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import './YearlyOverviewChart.css';
import { apiCall } from "../../services/ApiCall.js"; 
import config from "../../config/config.json";

const YearlyOverviewChart = () => {
  const [activeTab, setActiveTab] = useState('Requests');
  const [chartData, setChartData] = useState({
    Requests: [{ name: '', data: [] }],
    Companies: [{ name: '', data: [] }],
    Sites: [{ name: '', data: [] }],
  });
  

  const chartOptions = {
    chart: {
      type: 'line',
      toolbar: { show: false },
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    colors: ['rgba(168, 197, 218, 1)', 'rgba(31, 146, 84, 1)', 'rgba(157, 199, 184, 1)', 'rgba(117, 73, 255, 1)'],
    xaxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],
      labels: {
        style: {
          colors: '#666666',
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#666666',
          fontSize: '12px',
        },
        formatter: function(value) {
          return Math.round(value);
        },
      },
    },
    grid: {
      borderColor: '#e0e0e0',
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      marginLeft: '10px',
      markers: {
        radius: 12,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
  };

  const fetchChartData = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestGraphData`,
        {},
        {},
        "GET"
      );

      if (isSuccess) {
        const requestCounts = {
          'New Request': Array(12).fill(0),
          'In-Progress Request': Array(12).fill(0),
          'To-Be-Approved Request': Array(12).fill(0),
          'Approved Request': Array(12).fill(0),
        };
  
        const startYear = new Date().getFullYear(); 
  
        data.data.forEach((request) => {
          const { status, updatedAt } = request;
          const requestDate = new Date(updatedAt);
          const requestYear = requestDate.getFullYear();
          const requestMonth = requestDate.getMonth();
  
          if (requestYear.toString() === startYear.toString()) {
            switch (status) {
              case '0': 
                requestCounts['New Request'][requestMonth] += 1;
                break;
              case '1':
                requestCounts['In-Progress Request'][requestMonth] += 1;
                break;
              case '4': 
                requestCounts['Approved Request'][requestMonth] += 1;
                break;
              case '2': 
                requestCounts['To-Be-Approved Request'][requestMonth] += 1;
                break;
              default:
                break;
            }
          }
        });
  
        const requestsData = Object.keys(requestCounts).map((name) => ({
          name,
          data: requestCounts[name],
        }));
        setChartData((prevData) => ({
          ...prevData,
          Requests: requestsData,
        }));
       
      }
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  const getCompanyGraphData = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getCompanyGraphData`,
      {},
      {},
      "GET"
    );

    if (isSuccess) {
      const companyCounts = {
        'Active': Array(12).fill(0),
        'In-Active': Array(12).fill(0),
       
      };
      const startYear = new Date().getFullYear(); 

      data.data.forEach((company) => {
        const { status, createdAt } = company;
        const companyDate = new Date(createdAt);
        const companyYear = companyDate.getFullYear();
        const companyMonth = companyDate.getMonth();

        if (companyYear.toString() === startYear.toString()) {
          switch (status) {
            case 0: 
            companyCounts['Active'][companyMonth] += 1;
              break;
            case 1: 
            companyCounts['In-Active'][companyMonth] += 1;
              break;
            default:
              break;
          }
        }
      });

      const companyData = Object.keys(companyCounts).map((name) => ({
        name,
        data: companyCounts[name],
      }));

      setChartData((prevData) => ({
        ...prevData,
        Companies: companyData,
      }));
    }
  };

  const getSitesGraphData = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getSitesGraphData`,
      {},
      {},
      "GET"
    );

    if (isSuccess) {
      const companyCounts = {
        'Active': Array(12).fill(0),
        'In-Active': Array(12).fill(0),
       
      };
      const startYear = new Date().getFullYear(); 

      data.data.forEach((company) => {
        const { status, createdAt } = company;
        const companyDate = new Date(createdAt);
        const companyYear = companyDate.getFullYear();
        const companyMonth = companyDate.getMonth();

        if (companyYear.toString() === startYear.toString()) {
          switch (status) {
            case 0: 
            companyCounts['Active'][companyMonth] += 1;
              break;
            case 1:
            companyCounts['In-Active'][companyMonth] += 1;
              break;
            default:
              break;
          }
        }
      });

      const companyData = Object.keys(companyCounts).map((name) => ({
        name,
        data: companyCounts[name],
      }));
      setChartData((prevData) => ({
        ...prevData,
        Sites: companyData,
      }));
      
    }
  };


  useEffect(() => {
    fetchChartData(); 
    getSitesGraphData();
    getCompanyGraphData();
  }, []);

  return (
    <div className="yearly-overview-chart" style={{ width: '98%' }}>
      <h3 className="chart-title" style={{ backgroundColor: 'white' }}>Yearly Overview</h3>
      <p className="chart-subtitle">Case overview of each month</p>
      
      <div className="chart-tabs">
        <span
          className={`tab ${activeTab === 'Requests' ? 'active' : ''}`}
          onClick={() => setActiveTab('Requests')}
        >
          Requests
        </span>
        <span
          className={`tab ${activeTab === 'Companies' ? 'active' : ''}`}
          onClick={() => setActiveTab('Companies')}
        >
          Companies
        </span>
        <span
          className={`tab ${activeTab === 'Sites' ? 'active' : ''}`}
          onClick={() => setActiveTab('Sites')}
        >
          Sites
        </span>
      </div>

      <div className="chart-legend">
        <Chart
          options={chartOptions}
          series={chartData[activeTab]}
          type="line"
          height={400}
        />
      </div>
    </div>
  );
};

export default YearlyOverviewChart;

import React, { useState } from "react";
import { apiCall } from "../../../services/ApiCall";
import config from "../../../config/config.json";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const MainContact = ({ onPrevious, companyData, companyUserData ,setShowDetails}) => {
  const [userData, setUserData] = useState(companyUserData);
  const [errors, setErrors] = useState({});

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFormData = [...userData];
    updatedFormData[index] = { ...updatedFormData[index], [name]: value };
    setUserData(updatedFormData);
  };

  const validate = () => {
    const newErrors = {};
    userData.forEach((contact, index) => {
      if (!contact.fname.trim()) {
        newErrors[`fname_${index}`] = "First Name is required.";
      }
      if (!contact.email.trim()) {
        newErrors[`email_${index}`] = "Email is required.";
      } else if (!/\S+@\S+\.\S+/.test(contact.email)) {
        newErrors[`email_${index}`] = "Email is invalid.";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate()) return;
    
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}updateCompanyData`,
      {},
      { companyId: companyData.id, ...companyData, userData: userData },
      "POST"
    );
    if (isSuccess) {
      setShowDetails(false);
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      {userData && userData.length ? 
      <>
      {userData.map((contact, index) => (
        <div className="Main-contant" key={index}>
          <div style={styles.header}>
            <span>Main Contact</span>
            <div style={styles.headerActions}>
          
            </div>
          </div>
          <div style={styles.container}>
            <div style={styles.form}>
              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ flex: 1 }}>
                  <div style={styles.formGroup}>
                    <label style={styles.label}>First Name</label>
                    <input
                      style={styles.input}
                      type="text"
                      name="fname"
                      value={contact.fname}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                    {errors[`fname_${index}`] && (
                      <div style={styles.error}>{errors[`fname_${index}`]}</div>
                    )}
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div style={styles.formGroup}>
                    <label style={styles.label}>Last Name</label>
                    <input
                      style={styles.input}
                      type="text"
                      name="lname"
                      value={contact.lname}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ flex: 1 }}>
                  <div style={styles.formGroup}>
                    <label style={styles.label}>Email</label>
                    <input
                      style={styles.input}
                      type="email"
                      name="email"
                      value={contact.email}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                    {errors[`email_${index}`] && (
                      <div style={styles.error}>{errors[`email_${index}`]}</div>
                    )}
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div style={styles.formGroup}>
                    <label style={styles.label}>Phone</label>
                    <input
                      style={styles.input}
                      type="tel"
                      name="phone"
                      value={contact.phone}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div style={styles.formGroup}>
                    <label style={styles.label}>Designation</label>
                    <select
                      style={styles.select}
                      name="designation"
                      value={contact.designation}
                      onChange={(e) => handleInputChange(index, e)}
                    >
                     <option value="">Select Designation</option>
                    <option value="Owner">Owner</option>
                    <option value="District Manager">District Manager</option>
                    <option value="Compliance Manager">Compliance Manager</option>
                    <option value="General Manager">General Manager</option>
                    </select>
                  </div>
                </div>
              </div>
              <div style={styles.buttonContainer}>
                <button style={styles.button} onClick={onPrevious}>
                  Previous
                </button>
                <button style={styles.button} onClick={handleSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
      </> :
      <>
         <div className="Main-contant" >
          <div style={styles.header}>
            <span>Main Contact</span>
            <div style={styles.headerActions}>
          
            </div>
          </div>
          <div style={styles.container}>
            <div style={styles.form}>
              <div style={{display:"flex",justifyContent:"center", alignItems:"center", gap: "20px" }}>
                No Contact Data
              </div>
             
              <div style={styles.buttonContainer}>
                <button style={styles.button} onClick={onPrevious}>
                  Previous
                </button>
                <button style={styles.button} onClick={handleSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
      }
      
    </div>
  );
};

export default MainContact;

const styles = {
  container: {
    backgroundColor: "#f0f8ff",
  },
  title: {
    fontSize: "32px",
    color: "#0050A4",
    marginBottom: "20px",
  },
  header: {
    backgroundColor: "#0050A4",
    color: "white",
    padding: "10px",
    fontSize: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerActions: {
    display: "flex",
    alignItems: "center",
  },
  form: {
    backgroundColor: "#E2EDF9",
    padding: "20px",
  },
  formGroup: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    color: "#0050A4",
  },
  input: {
    width: "100%",
    padding: "8px",
    border: "1px solid #0050A4",
    color: "#0050A4",
    borderRadius: "4px",
  },
  select: {
    width: "100%",
    padding: "8px",
    border: "1px solid #0050A4",
    color: "#0050A4",
    borderRadius: "4px",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "#0050A4",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  addButton: {
    padding: "2px 10px",
    backgroundColor: "white",
    color: "#1C1C1C",
    border: "1px solid white",
    borderRadius: "4px",
    cursor: "pointer",
    marginRight: "10px",
  },
  deleteIcon: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  error: {
    color: "red",
    fontSize: "12px",
    marginTop: "5px",
  },
};
import React, { useEffect, useState } from "react";
import "./main.css";
import viewIcon from "../img/viewIcon.png";
import editIcon from "../img/editIcon.png";
import { useNavigate } from "react-router-dom";
import archiveIcon from "../img/archiveIcon.png";
import { apiCall } from "../../../services/ApiCall.js";
import Loader from "./Loader.js";
import config from "../../../config/config.json";
import { Modal, Button } from "react-bootstrap";
import ViewPage from "./ViewPage.js";
import EditCompany from "./EditCompany.js";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';


const Main = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [deletedMode, setDeletedMode] = useState("DELETE");
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [editData, setEditData] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [mode, setMode] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [phone, setPhone] = useState();
  const [showAddDetails, setShowAddDetails] = useState(false);

  const [errors, setErrors] = useState({});
  const [contacts, setContacts] = useState([{ fname: "", lname: "", email: "", phone: "", designation: "" }]);

  const handleChange = (index, key, value) => {
    const updatedContacts = contacts.map((contact, i) =>
      i === index ? { ...contact, [key]: value } : contact
    );
    setContacts(updatedContacts);
  };

  const addNewContact = () => {
    setContacts([...contacts, { fname: "", lname: "", email: "", phone: "", designation: "" }]);
  };
  const validate = () => {
    const newErrors = {};
    contacts.forEach((contact, index) => {
      const fname = contact.fname || "";
      const email = contact.email || "";
      const designation = contact.designation || "";
      
      if (!fname.trim()) {
        newErrors[`fname-${index}`] = "First Name is required";
      }
      if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
        newErrors[`email-${index}`] = "A valid Email is required";
      }
      if (!designation.trim()) {
        newErrors[`designation-${index}`] = "Designation is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addNewCompanyAndContact = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const { isSuccess } = await apiCall(
      `${config.POST_LOGIN_API_URL}addNewCompanyContact`,
      {},
      {contacts,companyId : Number(viewData.id)},
      "POST"
    );
    if (isSuccess) {
      getCompanyList();
      setShowAddDetails(false)
    }
  };

  const removeContact = (index) => {
    const updatedContacts = contacts.filter((_, i) => i !== index);
    setContacts(updatedContacts);
  };

  const handleActionIconClick = (id, mode) => {
    setDeleteId(id);
    setShowDeleteModal(true);
    setDeletedMode(mode);
  };

  const handleAction = () => {
    actionCompany(deleteId);
    setShowDeleteModal(false);
  };

  const sortData = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    const sortedData = [...data].sort((a, b) => {
      if (key === "sequence") {
        return direction === "ascending"
          ? a.sequence - b.sequence
          : b.sequence - a.sequence;
      }
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });
    setSortConfig({ key, direction });
    setData(sortedData);
  };

  const getCompanyList = async () => {
    setLoading(true);
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getCompanyList`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setData(data?.data?.reverse());
    }
    setLoading(false);
  };

  const actionCompany = async (id) => {
    const endpoint =
      deletedMode === "DELETE" ? "deleteCompany" : "archiveCompany";
    const { isSuccess } = await apiCall(
      `${config.POST_LOGIN_API_URL}${endpoint}`,
      {},
      { companyId: id },
      "POST"
    );
    if (isSuccess) {
      getCompanyList();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getCompanyList();
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else if (!showDetails && mode === 'EDIT') {
      getCompanyList();
    }
  }, [showDetails]);

  if (loading) {
    return <Loader />;
  }

  const numberedData = data.map((row, index) => ({
    ...row,
    sequence: index + 1,
  }));
  const filteredData = numberedData.filter((row) =>
    ["id", "araId", "name", "street", "city", "state", "zipcode"].some((key) =>
      row[key]?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  console.log(filteredData)

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const sortedData = [...filteredData].slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const renderSortIcons = (key) => {
    if (sortConfig.key !== key) return "▲▼";
    return sortConfig.direction === "ascending" ? "▲" : "▼";
  };

  const renderPagination = () => {
    const pageNumbers = [];
    let startPage = currentPage - 1;
    let endPage = currentPage + 1;
    if (startPage < 1) {
      startPage = 1;
      endPage = 3;
    }
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = totalPages - 2 > 0 ? totalPages - 2 : 1;
    }
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`pagination-item ${currentPage === i ? "active" : ""}`}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </li>
      );
    }
    return (
      <ul className="pagination">
        <li
          className={`pagination-item ${currentPage === 1 ? "disabled" : ""}`}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </li>
        {pageNumbers}
        <li
          className={`pagination-item ${currentPage === totalPages ? "disabled" : ""
            }`}
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
        >
          Next
        </li>
      </ul>
    );
  };

  const handlePageChange = (newPage, data) => {
    if (newPage === "addCompany") {
      navigate("/add-company");
    } else if (newPage === "viewSite") {
      navigate(`/site-list/view/${data.id}`);
    } else if (newPage === "view") {
      setViewData(data);
      setShowDetails(true);
      setMode("VIEW");
    } else if (newPage === "edit") {
      setMode("EDIT");
      setEditData(data);
      setShowDetails(true);
    }
  };

  return (
    <div className="container mt-1">
      <div className="controls row">
        <div className="show-entries col-lg-2">
          <label>Show </label>
          <select
            value={entriesPerPage}
            className="form-control"
            onChange={(e) => {
              setEntriesPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={250}>250</option>
            <option value={500}>500</option>
          </select>
          <label> entries</label>
        </div>
        <div className="search-box col-lg-8 text-center ">
          <input
            type="text"
            className="form-control"
            placeholder="Search by ID, Name, Street, City, State, or Zipcode..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="col-lg-2 text-end">
          <button
            className="add-site-button btn"
            onClick={() => handlePageChange("addCompany")}
            style={{
              backgroundColor: "#F7921E",
              color: "white",
              fontSize: "12px",
              padding: "8px 12px",
            }}
          >
            + Add Company
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-end">{renderPagination()}</div>

      <div
        className="company-border mt-3"
        style={{
          border: "1px solid #0050A4",
          borderRadius: "10px",
          padding: "2px",
        }}
      >
        <table className="company-table " style={{ fontSize: "14px" }}>
          <thead>
            <tr>
              <th style={{ width: "7%" }} onClick={() => sortData("sequence")}>
                ID {renderSortIcons("sequence")}
              </th>
              <th style={{ width: "12%" }} onClick={() => sortData("ara-id")}>
                ARA ID {renderSortIcons("ara-id")}
              </th>
              <th style={{ width: "17%" }} onClick={() => sortData("name")}>
                Name {renderSortIcons("name")}
              </th>
              <th style={{ width: "22%" }} onClick={() => sortData("street")}>
                Street {renderSortIcons("street")}
              </th>
              <th style={{ width: "10%" }} onClick={() => sortData("city")}>
                City {renderSortIcons("city")}
              </th>
              <th
                style={{ width: "10%" }}
                className="text-center"
                onClick={() => sortData("state")}
              >
                State {renderSortIcons("state")}
              </th>
              <th style={{ width: "32%" }} className="text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row) => (
              <tr key={row.id}>
                <td>{row.id}</td>
                <td>{row?.araId}</td>
                <td>{row?.name}</td>
                <td>{row?.street}</td>
                <td>{row?.city}</td>
                <td className="text-center">{row?.state}</td>

                <td>
                  <img
                    className="mx-2 cursor-pointer"
                    src={viewIcon}
                    alt="View"
                    title="View"
                    onClick={() => handlePageChange("view", row)}
                  />
                  <img
                    className="mx-2 cursor-pointer"
                    src={editIcon}
                    alt="Edit"
                    title="Edit"
                    onClick={() => handlePageChange("edit", row)}
                  />
                  <span
                    className="mx-2 cursor-pointer"
                    style={{
                      fontSize: "20px",
                      color: "#0050A4",
                      cursor:"pointer"
                    }}
                    title="Add Contact"

                    onClick={() => {
                      setShowAddDetails(true); setViewData(row);
                    }}>
                    +
                  </span>
                  <img
                    className="mx-2 cursor-pointer"
                    src={archiveIcon}
                    alt="Archive"
                    title="Archive"
                    onClick={() => handleActionIconClick(row.id, "ARCHIVE")}
                  />
                  <button
                    className="view-request-button btn btn-link p-2 ms-1"
                    style={{
                      border: "1px solid #0050A4",
                      textDecoration: "none",
                      backgroundColor: "#0050A4",
                      color: "white",
                      fontSize: "12px",
                    }}
                    onClick={() => handlePageChange("viewSite", row)}
                  >
                    View Site
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {renderPagination()}

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {deletedMode === "DELETE"
              ? "Delete Confirmation"
              : "Archive Confirmation"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to{" "}
          {deletedMode === "DELETE" ? "delete" : "archive"} this company?
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <Button
            variant="secondary"
            style={{
              color: "#0050A4",
              border: "2px solid #0050A4",
              backgroundColor: "white",
              fontWeight: "bold",
            }}
            onClick={() => setShowDeleteModal(false)}
          >
            Close
          </Button>
          <Button
            variant="danger"
            style={{
              color: "white",
              backgroundColor: "#0050A4",
              border: "1px solid #0050A4",
            }}
            onClick={handleAction}
          >
            {deletedMode === "DELETE" ? "Delete" : "Archive"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDetails} onHide={() => setShowDetails(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "32px", color: "#0050A4" }}>
            {mode === "VIEW" ? "Company Detail" : "Edit Company"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          {mode === "VIEW" ? (
            <ViewPage viewData={viewData} />
          ) : (
            <EditCompany editData={editData} setShowDetails={setShowDetails} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "#0050A4" }}
            onClick={() => setShowDetails(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

         <Modal show={showAddDetails} onHide={() => setShowAddDetails(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "32px", color: "#0050A4" }}>
          Add Company Contact
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {contacts.map((contact, index) => (
          <div key={index} className="contact-container">
            <div className="site-detail-h">
              <div className="site-detail-left">Company Contact</div>
              <div className="site-detail-right">
                <button className="me-4 ps-2 pe-2" onClick={addNewContact}>
                  + Add
                </button>
                {contacts.length > 1 && (
                  <button
                    className="me-4 ps-2 pe-2"
                    onClick={() => removeContact(index)}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
            <div className="site-contact">
              <form onSubmit={addNewCompanyAndContact}>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor={`fname-${index}`}>First Name</label>
                    <input
                      onChange={(e) => handleChange(index, "fname", e.target.value)}
                      value={contact.fname}
                      type="text"
                      id={`fname-${index}`}
                    />
                    {errors[`fname-${index}`] && (
                      <div className="error-message">
                        {errors[`fname-${index}`]}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor={`lname-${index}`}>Last Name</label>
                    <input
                      onChange={(e) => handleChange(index, "lname", e.target.value)}
                      value={contact.lname}
                      type="text"
                      id={`lname-${index}`}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor={`email-${index}`}>Email</label>
                    <input
                      onChange={(e) => handleChange(index, "email", e.target.value)}
                      value={contact.email}
                      type="text"
                      id={`email-${index}`}
                    />
                    {errors[`email-${index}`] && (
                      <div className="error-message">
                        {errors[`email-${index}`]}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                  <label style={{marginBottom:"6px"}} htmlFor={`phone-${index}`}>Phone</label>
               
               <PhoneInput
                       country={'us'} // Restricts to the US phone number format
                       value={contact.phone}
                       onChange={(phone) => handleChange(index, 'phone', phone)}
                       inputStyle={{
                        borderRadius:"8px",
                      
                       }} 
                     />
                  </div>
                  <div className="form-group">
                    <label htmlFor={`designation-${index}`}>Designation</label>
                    <select
                      onChange={(e) => handleChange(index, "designation", e.target.value)}
                      value={contact.designation}
                      id={`designation-${index}`}
                    >
                      <option value="">Select Designation</option>
                      <option value="Owner">Owner</option>
                      <option value="District Manager">District Manager</option>
                      <option value="Compliance Manager">Compliance Manager</option>
                      <option value="General Manager">General Manager</option>
                    </select>
                    {errors[`designation-${index}`] && (
                      <div className="error-message">
                        {errors[`designation-${index}`]}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group-foot">
                  <span className="text-start"></span>
                  <span className="text-end">
                    <button type="submit" className="next-btn ps-4 pe-4">
                      + SAVE
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ backgroundColor: "#0050A4" }}
          onClick={() => setShowAddDetails(false)}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
  );
};

export default Main;

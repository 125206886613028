import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import './RequestsStatus.css';
import { NavLink } from 'react-router-dom';
import { apiCall } from "../../services/ApiCall.js"; // Ensure this path is correct
import config from "../../config/config.json";

const RequestsStatus = () => {
  const [requestData, setRequestData] = useState({});
  const [chartSeries, setChartSeries] = useState([0, 0, 0, 0]);
  const [timeFrame, setTimeFrame] = useState('month'); // Default to month view
  const [selectedWeek, setSelectedWeek] = useState(1); // Default to week 1
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth(); // Current month index

  const getRequestData = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getRequestGraphData`,
      {},
      {},
      "GET"
    );

    if (isSuccess) {
      const requestDatas = data?.data.reduce((acc, request) => {
        const { status, id, createdAt, updatedAt } = request;
        if (!acc[status]) {
          acc[status] = [];
        }
        acc[status].push({ id, createdAt, updatedAt });
        return acc;
      }, {});
      setRequestData(requestDatas);
    }
  };

  useEffect(() => {
    getRequestData();
  }, []);

  useEffect(() => {
    const newRequests = requestData['New Requests'] || [];
    const inProgressRequests = requestData['In-Progress Requests'] || [];
    const toBeApprovedRequests = requestData['To-Be-Approved Requests'] || [];
    const approvedRequests = requestData['Approved Inspections'] || [];

    let newCount = 0;
    let inProgressCount = 0;
    let toBeApprovedCount = 0;
    let approvedCount = 0;

    if (timeFrame === 'month') {
      // Count requests for the current month
      newCount = newRequests.filter(item => new Date(item.updatedAt).getMonth() === currentMonth && new Date(item.updatedAt).getFullYear() === currentYear).length;
      inProgressCount = inProgressRequests.filter(item => new Date(item.updatedAt).getMonth() === currentMonth && new Date(item.updatedAt).getFullYear() === currentYear).length;
      toBeApprovedCount = toBeApprovedRequests.filter(item => new Date(item.updatedAt).getMonth() === currentMonth && new Date(item.updatedAt).getFullYear() === currentYear).length;
      approvedCount = approvedRequests.filter(item => new Date(item.updatedAt).getMonth() === currentMonth && new Date(item.updatedAt).getFullYear() === currentYear).length;
    } else if (timeFrame === 'week') {
      // Count requests for the selected week
      const startOfWeek = new Date(currentYear, currentMonth, 1 + (selectedWeek - 1) * 7); // Start of selected week
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6); // End of selected week

      newCount = newRequests.filter(item => {
        const updatedAt = new Date(item.updatedAt);
        return updatedAt >= startOfWeek && updatedAt <= endOfWeek;
      }).length;

      inProgressCount = inProgressRequests.filter(item => {
        const updatedAt = new Date(item.updatedAt);
        return updatedAt >= startOfWeek && updatedAt <= endOfWeek;
      }).length;

      toBeApprovedCount = toBeApprovedRequests.filter(item => {
        const updatedAt = new Date(item.updatedAt);
        return updatedAt >= startOfWeek && updatedAt <= endOfWeek;
      }).length;

      approvedCount = approvedRequests.filter(item => {
        const updatedAt = new Date(item.updatedAt);
        return updatedAt >= startOfWeek && updatedAt <= endOfWeek;
      }).length;
    }

    setChartSeries([newCount, inProgressCount, toBeApprovedCount, approvedCount]);
  }, [requestData, timeFrame, selectedWeek]);

  const chartOptions = {
    chart: {
      type: 'donut',
    },
    labels: ['New Requests', 'In-Progress Requests', 'To-Be-Approved Requests', 'Approved Inspections'],
    colors: chartSeries.every(count => count === 0) ? ['#8B4513', '#8B4513', '#8B4513', '#8B4513'] : ['#5D8DEC', '#3366CC', '#476195', '#8DADEB'], // Change color to brown if all counts are zero
    legend: { show: false },
    dataLabels: { enabled: false },
    plotOptions: {
      pie: {
        donut: {
          size: '40%',
        }
      }
    }
  };

  return (
    <div className="requests-status">
      <div className="status-header">
        <h2 className="status-title">Requests Status</h2>
        <select className="status-select" onChange={(e) => {
          setTimeFrame(e.target.value);
          if (e.target.value === 'month') {
            setSelectedWeek(1); // Reset to week 1 if month is selected
          }
        }}>
          <option value="month">This Month</option>
          <option value="week">This Week</option>
        </select>

        {timeFrame === 'month' && (
          <select className="week-select" onChange={(e) => setSelectedWeek(Number(e.target.value))}>
            <option value="1">Week 1</option>
            <option value="2">Week 2</option>
            <option value="3">Week 3</option>
            <option value="4">Week 4</option>
          </select>
        )}
      </div>
      <div className="status-content">
        <div className="status-chart">
          <Chart options={chartOptions} series={chartSeries} type="donut" width={320} height={360} />
        </div>
        <div className="status-legend">
          {[
            { label: 'New Requests', link: '/new-request' },
            { label: 'In-Progress Requests', link: '/in-progress' },
            { label: 'To-Be-Approved Requests', link: '/to-be-approved' },
            { label: 'Approved Inspections', link: '/approved-Request' }
          ].map((item, index) => (
            <div key={index} className="legend-item">
              <div className="legend-content">
                <div className={`legend-color color-${index + 1}`}></div>
                <NavLink to={item.link} className="legend-label">
                  {item.label}
                </NavLink>
                <div className="legend-value">{chartSeries[index]}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RequestsStatus;

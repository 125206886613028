import React, { useEffect, useState } from "react";
import CancelInspectionModal from "./CancelInspectionModal.js";
import AssignInspectionModal from "./AssignInspectionModal.js";
import ReassignModel from "./ReassignModel.js";
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";
import pdficon from "./img/pdf.png"
import cancelInspectionIcon from "./img/Cancel Inspection.png";
import assignInspectionIcon from "./img/Assign Inspection.png";
import reassignIcon from "./img/Reassign.png";
import editIcon from "./img/Edit.png";
import archiveIcon from "./img/Archive.png";
import viewInspectionIcon from "./img/Group.png";
import filterIcon from "./img/filter.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { apiCall } from "../../services/ApiCall.js";
import config from "../../config/config.json";
import { Button, Modal } from "react-bootstrap";
import Loader from "../CompanyFolder/CompanyList/Loader.js";
import { IoChevronBackCircle } from "react-icons/io5";

const Inspections = ({ type, status }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [requestData, setRequestData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [expandedRows, setExpandedRows] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendModalIsOpen, setResendModalIsOpen] = useState(false);
  const [assignModalIsOpen, setAssignModalIsOpen] = useState(false);
  const [reassignModelIsOpen, setReassignModalIsOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [actionMode, setActionMode] = useState("DELETE");
  const [requestId, setRequestId] = useState(null);

  const statusMap = {
    0: "New Request",
    1: "In-Progress",
    2: "To-Be-Approved",
    3: "Migrated",
    4: "Approved",
    5: "Waiting for Approval",
    6: "Renewal Scheduled",
    7: "Redo",
    8: "To be Approved",
    9: "Archived",
  };

  const handleAction = () => {
    archiveRequest(requestId);
    setShowDeleteModal(false);
  };

  const handleReassignClick = () => {
    setReassignModalIsOpen(true);
  };

  const handleAssigninspectionClick = () => {
    setAssignModalIsOpen(true);
  };

  const handleResendClick = () => {
    setResendModalIsOpen(true);

    console.log("Resend button clicked");
  };

  const handleCloseReassignModel = () => {
    setReassignModalIsOpen(false);
  };
  const handleCloseAssignModal = () => {
    setAssignModalIsOpen(false);
  };

  const handleCloseResendModal = () => {
    setResendModalIsOpen(false);
  };

  const handleCancelClick = (id) => {
    setModalIsOpen(true);
    setActionMode("DELETE");
    setRequestId(id);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const filteredData = requestData?.length
    ? requestData.filter((item) =>
      ["siteId","id", "araId", "name", "street","siteAddress", "siteCity","companyName", "state", "zipcode"].some((key)=>
        item[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      
      )
    )
    : [];
    console.log(filteredData)

  const sortedData = filteredData.length
    ? filteredData.sort((a, b) => {
      if (!sortColumn) return 0;
      if (a[sortColumn] < b[sortColumn])
        return sortDirection === "asc" ? -1 : 1;
      if (a[sortColumn] > b[sortColumn])
        return sortDirection === "asc" ? 1 : -1;
      return 0;
    })
    : [];

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = sortedData.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleModeChange = (mode, id) => {
    setActionMode(mode);
    setRequestId(id);
    setShowDeleteModal(true);
  };

  const handleSortColumn = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleViewMore = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      // [id]: true,
      [id]: !prev[id],
    }));
  };

  const archiveRequest = async () => {
    let endpoint = "";

    if (actionMode === "DELETE") {
      endpoint = "cancelRequest";
    } else if (actionMode === "UNARCHIVE") {
      endpoint = "unArchiveRequest";
    } else {
      endpoint = "archiveRequest";
    }

    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}${endpoint}`,
      {},
      { requestId },
      "POST"
    );

    if (isSuccess) {
      getRequestsList();
      handleCloseResendModal();
    }
  };
  const [queryParams, setQueryParams] = useState({});
  const [hashParams, setHashParams] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramsObject = {};
    for (let [key, value] of searchParams.entries()) {
      paramsObject[key] = value;
    }
    setQueryParams(paramsObject);
    const hash = location.hash;
    if (hash) {
      setHashParams(hash.substring(1));

    }
  }, [location]);

  const getRequestsList = async () => {
    setLoading(true)
    const msiId = queryParams.requestId;
    const match = location.pathname.match(/\/all-request\/view\/(\d+)/);
    // if ((match && match[1]) || msiId || status) {


      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestsList`,
        {},
        { type, status, siteId: match && match[1], requestId: msiId || null },
        "GET"
      );
      setLoading(false);

      if (isSuccess) {
        setLoading(false)
        setRequestData(data?.data?.reverse());
      }
    // }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    getRequestsList();
  }, [type, status, location, queryParams]);
  const renderPagination = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(filteredData.length / entriesPerPage);

    let startPage = currentPage - 1;
    let endPage = currentPage + 1;

    if (startPage < 1) {
      startPage = 1;
      endPage = 3;
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = totalPages - 2 > 0 ? totalPages - 2 : 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`pagination-item ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </li>
      );
    }

    return (
      <ul className="pagination">
        <li
          className={`pagination-item ${currentPage === 1 ? "disabled" : ""}`}
          onClick={handlePreviousPage}
        >
          Previous
        </li>
        {pageNumbers}
        <li
          className={`pagination-item ${currentPage === totalPages ? "disabled" : ""
            }`}
          onClick={handleNextPage}
        >
          Next
        </li>
      </ul>
    );
  };
  return (
    <div className="inspections-container  p-0">
      <div className="controls">
        <div className="show-entries col-lg-2">
          <label>Show </label>
          <select
            value={entriesPerPage}
            onChange={(e) => setEntriesPerPage(Number(e.target.value))}
          >
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={250}>250</option>
            <option value={500}>500</option>
          </select>
          <label> entries</label>
        </div>

        <span className="searchbox">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </span>
        <img src={filterIcon} alt="" />
        {status != 9 && (!queryParams?.hide) && (
          <NavLink to="/add-request" style={{ textDecoration: "none" }}>
            <button
              className="add-inspection-button"
            // onClick={() => setModalIsOpen(true)}
            >
              + Add Request
            </button>
          </NavLink>
        )}
      </div>
      {!loading ? <div className="bodyCo ">
        <div class="table-responsive mt-5">
          <div className="table ">
            <table className="inspections-table">
              <thead className="thead">
                <tr>
                  {Number(status) === 4 || type === "ALL" ? (
                    <th
                      style={{ width: "5%" }}
                      onClick={() => handleSortColumn("id")}
                    >
                      ID{" "}
                      {sortColumn === "id" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                  ) : (
                    <th
                      style={{ width: "12%" }}
                      onClick={() => handleSortColumn("id")}
                    >
                      ID{" "}
                      {sortColumn === "id" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                  )}
                  {Number(status) === 4 || type === "ALL" ? (
                    <th
                      style={{ width: "8%" }}
                      onClick={() => handleSortColumn("araId")}
                    >
                      ARA ID{" "}
                      {sortColumn === "araId" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                  ) : (
                    <th
                      style={{ width: "15%" }}
                      onClick={() => handleSortColumn("araId")}
                    >
                      ARA ID{" "}
                      {sortColumn === "araId" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                  )}
                  {type === "ALL" ? (
                    <th
                      style={{ width: "15%" }}
                      onClick={() => handleSortColumn("company")}
                    >
                      Company{" "}
                      {sortColumn === "company" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                  ) : (
                    <th
                      style={{ width: "25%" }}
                      onClick={() => handleSortColumn("company")}
                    >
                      Company{" "}
                      {sortColumn === "company" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                  )}
                  {type === "ALL" ? (
                    <th
                      style={{ width: "12%" }}
                      onClick={() => handleSortColumn("site")}
                    >
                      Site{" "}
                      {sortColumn === "site" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                  ) : (
                    <th
                      style={{ width: "25%" }}
                      onClick={() => handleSortColumn("site")}
                    >
                      Site{" "}
                      {sortColumn === "site" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                  )}
                  {Number(status) === 4 || type === "ALL" ? (
                    <th
                      style={{ width: "10%" }}
                      onClick={() => handleSortColumn("approvalDate")}
                    >
                      Approval Date{" "}
                      {sortColumn === "approvalDate" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                  ) : (
                    <></>
                  )}
                  {Number(status) === 2 || type === "ALL" ? (
                    <th
                      style={{ width: "12%" }}
                      onClick={() => handleSortColumn("approvalDate")}
                    >
                      Completed Date{" "}
                      {sortColumn === "CompletedDate" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                  ) : (
                    <></>
                  )}
                  {Number(status) === 4 || type === "ALL" ? (
                    <th
                      style={{ width: "16%" }}
                      onClick={() => handleSortColumn("nextInspectionDate")}
                    >
                      Next Inspection Date{" "}
                      {sortColumn === "nextInspectionDate" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                  ) : (
                    <></>
                  )}
                  {type === "ALL" ? (
                    <th
                      style={{ width: "12%" }}
                      onClick={() => handleSortColumn("Status")}
                    >
                      Status{" "}
                      {sortColumn === "Status" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                  ) : (
                    <></>
                  )}
                  <th style={{ width: "14%" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries.map((item) => (
                  <tr key={item.id}>
                    <td>{item?.id}</td>
                    <td>{item?.araId}</td>
                    <td>{item?.companyName}</td>
                    <td>
                      {item?.siteAddress +
                        ", " +
                        item?.siteCity +
                        ", " +
                        item?.siteState}
                    </td>
                    {(Number(status) === 4 || type === "ALL") && (
                      <td>
                        {item?.approvedDate
                          ? new Date(item?.approvedDate).toLocaleDateString(
                            "en-US",
                            { year: "numeric", month: "long", day: "numeric" }
                          )
                          : ""}
                      </td>
                    )}
                    {(Number(status) === 2 || type === "ALL") && (
                      <td>
                        {item?.completedDate
                          ? new Date(item?.completedDate).toLocaleDateString(
                            "en-US",
                            { year: "numeric", month: "long", day: "numeric" }
                          )
                          : ""}
                      </td>
                    )}
                    {(Number(status) === 4 || type === "ALL") && (
                      <td>
                        {item?.inspectionDate
                          ? new Date(item?.inspectionDate).toLocaleDateString(
                            "en-US",
                            { year: "numeric", month: "long", day: "numeric" }
                          )
                          : ""}
                      </td>
                    )}
                    {type === "ALL" && (
                      <td>
                        <div className={`status-${item?.status}`}>
                          {statusMap[item?.status] || "Unknown Status"}
                        </div>
                      </td>
                    )}
                    <td>
                      {status === 9 ? (
                        <img
                          src={archiveIcon}
                          alt="Archive"
                          title="Archive"
                          onClick={() => handleModeChange("UNARCHIVE", item.id)}
                        />
                      ) : !expandedRows[item.id] ? (
                        <button
                          className="view-more-button"
                          style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}
                          onClick={() => handleViewMore(item.id)}
                        >
                          <NavLink
                            to="/request-form"
                            state={{ requestId: item.id, requestData: item }}
                            style={{ textDecoration: "none" }}
                          >
                            <img src={editIcon} alt="Edit Form" title="Edit Form" />
                          </NavLink>

                          {!queryParams?.hide && (
                            <>
                              <NavLink
                                to="/view-inspection-request"
                                state={{ requestId: item.id, requestData: item }}
                                style={{ textDecoration: "none" }}
                              >
                                <img src={viewInspectionIcon} alt="View Inspection" title="View Inspection" />
                              </NavLink>

                              <NavLink
                                to="/view-pdf-report"
                                state={{ requestId: item.id, requestData: item }}
                                style={{ textDecoration: "none" }}
                              >
                                <img src={pdficon} alt="View PDF" title="View PDF" />
                              </NavLink>

                              <span className="view" style={{ textDecoration: "none" }}>
                                View More
                              </span>
                            </>
                          )}
                        </button>
                      ) : (
                        <div className="additional-icons">
                          <NavLink
                            to="/request-form"
                            state={{ requestId: item.id, requestData: item }}
                            style={{ textDecoration: "none" }}
                          >
                            <img src={editIcon} alt="Edit Form" title="Edit Form" />
                          </NavLink>

                          {!queryParams?.hide && (
                            <>
                              <NavLink
                                to="/view-inspection-request"
                                state={{ requestId: item.id, requestData: item }}
                                style={{ textDecoration: "none" }}
                              >
                                <img
                                  src={viewInspectionIcon}
                                  alt="View Inspection"
                                  className="me-2 ms-2"
                                  title="View Inspection"
                                />
                              </NavLink>

                              <img
                                src={cancelInspectionIcon}
                                alt="Cancel Inspection"
                                title="Cancel Inspection"
                                onClick={() => handleCancelClick(item.id)}
                              />
                              <CancelInspectionModal
                                isOpen={modalIsOpen}
                                onRequestClose={handleCloseModal}
                                onConfirm={archiveRequest}
                              />

                          <img
                            src={assignInspectionIcon}
                            alt="Assign Inspection"
                            title="Assign Inspection"
                            onClick={handleAssigninspectionClick}
                          />
                          <AssignInspectionModal
                          getRequestsList={getRequestsList}
                            isOpen={assignModalIsOpen}
                            item={item}
                            onRequestClose={handleCloseAssignModal}
                          />

                              <img
                                src={reassignIcon}
                                alt="Reassign"
                                title="Reassign"
                                onClick={handleReassignClick}
                                className="me-2 ms-2"
                              />
                              <ReassignModel
                                isOpen={reassignModelIsOpen}
                                onRequestClose={handleCloseReassignModel}
                              />
                            </>
                          )}

                          <img
                            src={archiveIcon}
                            alt="Archive"
                            title="Archive"
                            onClick={() => handleModeChange("EDIT", item.id)}
                          />

                          <div
                            onClick={() => handleViewMore(item.id)}
                            style={{ cursor: "pointer" }}
                          >
                            <IoChevronBackCircle title="View Less" style={{ color: "green" }} />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {totalPages > 1 && renderPagination()}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {actionMode === "DELETE"
                ? "Delete Confirmation"
                : actionMode === "UNARCHIVE"
                  ? "Un-Archive Confirmation"
                  : "Archive Confirmation"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to{" "}
            {actionMode === "DELETE"
              ? "delete"
              : actionMode === "UNARCHIVE"
                ? "Un-Archive"
                : "archive"}{" "}
            this Request?
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "space-between" }}>
            <Button
              variant="secondary"
              style={{
                color: "#0050A4",
                border: "2px solid #0050A4",
                backgroundColor: "white",
                fontWeight: "bold",
              }}
              onClick={() => setShowDeleteModal(false)}
            >
              Close
            </Button>
            <Button
              variant="danger"
              style={{
                color: "white",
                backgroundColor: "#0050A4",
                border: "1px solid #0050A4",
              }}
              onClick={handleAction}
            >
              {actionMode === "DELETE"
                ? "Delete"
                : actionMode === "UNARCHIVE"
                  ? "Un-Archive"
                  : "Archive"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div> : <Loader />}
    </div>
  );
};

export default Inspections;

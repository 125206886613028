import React, { useState } from "react";
import "./CompanyContact.css";
import deleteIcon from "../img/deleteIcon.png";
import exportIcon from "../img/exportIcon.png";
import { apiCall } from "../../../services/ApiCall";
import config from "../../../config/config.json";
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const CompanyContact = ({ answer, setAnswer, onSwitchToDetail }) => {
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([answer.userData]);
  const [phone, setPhone] = useState();

  const [errors, setErrors] = useState({});

  const handleChange = (index, key, value) => {
    const updatedContacts = contacts.map((contact, i) =>
      i === index ? { ...contact, [key]: value } : contact
    );
    setContacts(updatedContacts);
  };

  const addNewContact = () => {
    setContacts([...contacts, { fname: "", lname: "", email: "", phone: "", designation: "" }]);
  };

  const validate = () => {
    const newErrors = {};
    contacts.forEach((contact, index) => {
      const fname = contact.fname || "";
      const email = contact.email || "";
      const designation = contact.designation || "";
      
      if (!fname.trim()) {
        newErrors[`fname-${index}`] = "First Name is required";
      }
      if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
        newErrors[`email-${index}`] = "A valid Email is required";
      }
      if (!designation.trim()) {
        newErrors[`designation-${index}`] = "Designation is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addNewCompanyAndContact = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const updatedAnswer = { ...answer, userData: contacts };
    const { isSuccess } = await apiCall(
      `${config.POST_LOGIN_API_URL}addNewCompanyAndContact`,
      {},
      updatedAnswer,
      "POST"
    );
    if (isSuccess) {
      navigate("/company-list");
    }
  };

  return (
    <div className="add-site">
      <div className="header">
        <h2>Add Company</h2>
    
      </div>
      <div className="tabs">
        <button className="tab" onClick={onSwitchToDetail}>
          Company Detail
        </button>
        <button className="tab active">Main Contact</button>
      </div>

      {contacts.map((contact, index) => (
        <div key={index} className="contact-container">
          <div className="site-detail-h">
            <div className="site-detail-left">Company contact</div>
            <div className="site-detail-right">
              <button className="me-4 ps-2 pe-2" onClick={addNewContact}>
                + Add
              </button>           
            </div>
          </div>
          <div className="site-contact">
            <form onSubmit={addNewCompanyAndContact}>
              <div className="form-row">
                <div className="form-group">
                  <span htmlFor={`fname-${index}`}>First Name</span>
                  <input
                    onChange={(e) => handleChange(index, "fname", e.target.value)}
                    value={contact.fname}
                    type="text"
                    id={`fname-${index}`}
                  />
                  {errors[`fname-${index}`] && <div className="error-message">{errors[`fname-${index}`]}</div>}
                </div>
                <div className="form-group">
                  <span htmlFor={`lname-${index}`}>Last Name</span>
                  <input
                    onChange={(e) => handleChange(index, "lname", e.target.value)}
                    value={contact.lname}
                    type="text"
                    id={`lname-${index}`}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <span htmlFor={`email-${index}`}>Email</span>
                  <input
                    onChange={(e) => handleChange(index, "email", e.target.value)}
                    value={contact.email}
                    type="text"
                    id={`email-${index}`}
                  />
                  {errors[`email-${index}`] && <div className="error-message">{errors[`email-${index}`]}</div>}
                </div>
                <div className="form-group">
                  <span style={{marginBottom:"2px"}} htmlFor={`phone-${index}`}>Phone</span>
               
                  <PhoneInput
                          country={'us'} // Restricts to the US phone number format
                          value={contact.phone}
                          onChange={(phone) => handleChange(index, "phone", phone)}
                          inputStyle={{
                           borderRadius:"8px"
                          }} // You can add your styles here
                        />
                </div>
                <div className="form-group">
                  <span htmlFor={`designation-${index}`}>Designation</span>
                  <select
                    onChange={(e) => handleChange(index, "designation", e.target.value)}
                    value={contact.designation}
                    id={`designation-${index}`}
                  >
                    <option value="">Select Designation</option>
                    <option value="Owner">Owner</option>
                    <option value="District Manager">District Manager</option>
                    <option value="Compliance Manager">Compliance Manager</option>
                    <option value="General Manager">General Manager</option>
                  </select>
                  {errors[`designation-${index}`] && <div className="error-message">{errors[`designation-${index}`]}</div>}
                </div>
              </div>
              <div className="form-group-foot">
                <span className="text-start">
                  <button
                    type="button"
                    className="previous-btn"
                    onClick={onSwitchToDetail}
                  >
                    Previous
                  </button>
                </span>
                <span className="text-end">
                  <button type="submit" className="next-btn ps-4 pe-4">
                    + SAVE
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CompanyContact;

import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import logo from "./img/logo 1.png";
import "./Login.css"; // Import the CSS file

import config from "../../../config/config.json";
import { apiCall } from "../../../services/ApiCall";
import { useLocation, useNavigate } from "react-router-dom";
import { Base64 } from 'js-base64';
import Loader from "../../CompanyFolder/CompanyList/Loader";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { isSuccess, data } = await apiCall(
      `${config.AUTH_API_URL}login`,
      {},
      {
        email: email,
        password: password,
        userType: 'ADMIN'
      },
      "POST"
    );

    if (isSuccess) {
      localStorage.setItem("userData", JSON.stringify(data.data));
      localStorage.setItem("token", JSON.stringify(data.data?.accessToken));
      console.log(data.data,"sdfefwdqdseget")
      navigate("/dashboard");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const loginAdmin = async (hide=false,email, password,decodedId) => {
    const { isSuccess, data } = await apiCall(
      `${config.AUTH_API_URL}login`,
      {},
      {
        email: email,
        password: password,
      },
      "POST"
    );

    if (isSuccess) {
      localStorage.setItem("userData", JSON.stringify(data.data));
      localStorage.setItem("token", JSON.stringify(data.data?.accessToken));
      setShowLoader(false);  
      let pushToRoute;
      if (window.location.href.includes("mainContactLogin")) {
        pushToRoute = `/#/all-request?hide=${hide}&mainContactLogin=true&requestId=${decodedId}`;
      } else if (window.location.href.includes("siteManagerLogin")) {
        pushToRoute = `/#/request-form?hide=${hide}&siteManagerLogin=true&requestId=${decodedId}`;
      } else if (window.location.href.includes("siteAdminLogin")) {
        pushToRoute = `/#/request-form?hide=${hide}&siteAdminLogin=true&requestId=${decodedId}`;
      }
      
      if (pushToRoute) {
        window.location.href = pushToRoute;  
      }
  }
  };


  const functionextractAndDecodeUrl = (url) => {
    const urlObj = new URL(url);
    const encodedId = urlObj.searchParams.get('id');
    const encodedUser = urlObj.searchParams.get('user');
    const encodedPassword = urlObj.searchParams.get('password');

    if (encodedId && encodedUser && encodedPassword) {
      const decodedId = Base64.decode(encodedId);
      const decodedEmail = Base64.decode(encodedUser);
      const decodedPassword = Base64.decode(encodedPassword);
      loginAdmin(true,decodedEmail, decodedPassword,decodedId)

    } else {
      console.error("Missing parameters in URL.");
    }
  };

  useEffect(() => {


    const currentUrl = window.location.href;
    if (currentUrl.includes("mainContactLogin") || currentUrl.includes("siteManagerLogin") || currentUrl.includes("siteAdminLogin")) {
      setShowLoader(true)
      functionextractAndDecodeUrl(currentUrl);
    }
  }, []);

  if (showLoader) {
    return <Loader />
  }


  return (
    <div style={styles.container}>
      <div style={{}}>
        <div style={styles.logo}>
          <img src={logo} alt="" />
        </div>
        <div className="align-item-center" style={{ justifyContent: "" }}>
          <div
            className="text-center"
            style={{ color: "white", marginBottom: "1.5em" }}
          >
            Sign-in
          </div>
          <input
            type="email"
            placeholder="Email Address *"
            style={styles.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div style={{ position: "relative" }}>
            <input
              className="Password"
              placeholder="Password *"
              style={styles.input}
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
            />
            <i
              className={`fa-solid fa-eye${showPassword ? "-slash" : ""}`}
              onClick={togglePasswordVisibility}
              style={styles.eyeIcon}
            ></i>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "3em",
          }}
        >
          <a href="#" style={styles.link}>
            <button style={styles.button} onClick={(e) => handleSubmit(e)}>
              LOGIN <FaArrowRight />
            </button>
          </a>
          <a style={styles.link}>Forgot your password?</a>
        </div>
      </div>
    </div>
  );
}

export default Login;
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "rgba(0, 80, 164, 1)", // Dark blue background
  },
  card: {
    backgroundColor: "#1A4D8F", // Slightly lighter blue
    borderRadius: "10px",
    padding: "40px 20px",
    //   width: "350px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  logo: {
    color: "#FFFFFF",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "2em",
  },
  input: {
    width: "100%",

    padding: "10px",
    marginBottom: "1em",

    borderRadius: "5px",
    backgroundColor: "rgba(0, 80, 164, 1)",
    color: "white",
    border: "1px solid white",
    justifyContent: "center",
    fontSize: "16px",
    paddingRight: "40px", // Make room for the icon
  },
  eyeIcon: {
    position: "absolute",
    right: "10px",
    top: "25%",
    // bottom:'50%',
    // transform: "transla teY(-50%)",
    cursor: "pointer",
    color: "black", // Make sure the icon is visible
  },

  button: {
    //   width: "100%",
    display: "inline-flex",
    alignItems: "center",
    gap: "8px",
    fontWeight: "bold",
    padding: "8px 22px",
    backgroundColor: "white",
    color: "rgba(0, 80, 164, 1)",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  link: {
    marginTop: "10px",
    display: "block",
    color: "#FFFFFF",
    textDecoration: "none",
    fontSize: "14px",
  },
};
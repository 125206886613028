import React, { useEffect, useState, useRef } from 'react';
import mysiteInspectionLogo from './img/mysiteInspectionLogo.png';
import { useLocation, useNavigate } from "react-router-dom";
import { apiCall } from "../../services/ApiCall";
import config from "../../config/config.json";
import PDF from './pdf';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

const VieInspectionReportPDF = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [uploadedImages, setUploadedImages] = useState({});
  const [modules, setModules] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [requestData, setRequestData] = useState();
  const pdfRef = useRef();  

  const getRequestUserData = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestUserData`,
        {},
        { requestId: location.state?.requestId },
        "GET"
      );
      if (isSuccess) {
        setRequestData(data.data);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const getRequestPictures = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestPictures`,
        {},
        { requestId: location.state?.requestId },
        "GET"
      );
      if (isSuccess && data.data.length) {
        setUploadedImages(data.data[0]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const getDataFormQuestionList = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getPDFQuestions`,
        {},
        { type: "ALL" },
        "GET"
      );
      if (isSuccess && Array.isArray(data.data)) {
        setModules(data.data);
      } else {
        console.error("API response is not an array:", data);
        setModules([]);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setModules([]);
    }
  };

  const getRequestDataFormAnswer = async () => {
    try {
      const { isSuccess, data } = await apiCall(
        `${config.POST_LOGIN_API_URL}getRequestDataFormAnswer`,
        {},
        { requestId: location.state?.requestId },
        "GET"
      );
      if (isSuccess) {
        setAnswers(data.data);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

const generatePDF = async () => {
  const input = pdfRef.current;
  const pdf = new jsPDF('p', 'mm', 'a4');
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();

  const canvas = await html2canvas(input, { scale: 2 });
  const imgData = canvas.toDataURL('image/png');

  const imgProps = pdf.getImageProperties(imgData);
  const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

  let heightLeft = imgHeight;
  let position = 0;

  pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);

  heightLeft -= pdfHeight;

  while (heightLeft > 0) {
    position -= pdfHeight;
    pdf.addPage();
    pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
    heightLeft -= pdfHeight;
  }

  const pdfBlob = pdf.output('blob');
  uploadToS3(pdfBlob);
};




const uploadToS3 = async (pdfBlob) => {
    try {
      const timestamp = new Date().getTime();
      const fileName = `${timestamp}_report.pdf`;
      const formData = new FormData();
      formData.append("file", pdfBlob, fileName);
      formData.append("fileName", fileName);
      formData.append("filePath", "msi/");
  
      const requestOptions = {
        method: "POST",
        body: formData,
      };
  
      const response = await fetch(`${config.AUTH_API_URL}uploadFile`, requestOptions);
      if (!response.ok) {
        throw new Error("Failed to upload file");
      }
  
      const result = await response.json();
      const url = result.url;
      console.log('PDF uploaded successfully. File URL:', url);
  
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // Set the download attribute
      document.body.appendChild(link); // Append the link to the body
      link.click(); // Programmatically trigger the click event to download
      document.body.removeChild(link); // Remove the link after download
    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getDataFormQuestionList();
      getRequestUserData();
      getRequestPictures();
      getRequestDataFormAnswer();
    }
  }, [navigate]);

  return (
    <div style={styles.container}>
      <button onClick={generatePDF} style={styles.generatePDFButton}>Generate </button>

      <div className='header' style={{ backgroundColor: '#DCEDFF', padding: '30px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>

        <div>
          <img
            src={mysiteInspectionLogo} alt="MySiteInspection.com"
            style={styles.logo}
          />
        </div>
        <div>
          <span style={{ marginRight: '1em' }}>Support@mysiteinspection.com</span>
          <strong style={{ marginRight: '1em' }}>401.463.0200</strong>
        </div>
      </div>

      <div style={{display:"flex",alignItems:"center", justifyContent:"center"}}>
        <div style={{fontSize:"29px", fontWeight:600}}>
          Site Inspection Compliance Report
        </div>
      </div>

      <div ref={pdfRef}>
        {modules.map((module, index) => (
          <PDF requestData={requestData} index={index} module={module} answer={answers[module.moduleTitle]} />
        ))}
      </div>

    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  },
  logo: {
    height: '40px',
  },
  generatePDFButton: {
    backgroundColor: '#0050A4',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
  },
};

export default VieInspectionReportPDF;

import React, { useEffect, useState } from 'react';
import SiteContact from './EditSiteContact';
import PlacesAutocomplete, { geocodeByAddress, } from "react-places-autocomplete";
import { useNavigate } from 'react-router-dom';
import { apiCall } from '../../../services/ApiCall';
import config from '../../../config/config.json';


const EditSite = ({ onSave, setShowDetails, editData }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState("editCompany");

  const [siteData, setSiteData] = useState({});
  const [siteUserData, setSiteUserData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [formData, setFormData] = useState({
    id: siteData?.id || '',
    araId: siteData?.araId || '',
    company: siteData?.company || '',
    companyId: siteData?.companyId || '',
    recheckCycle: siteData?.recheckCycle || '',
    address: siteData?.address || '',
    address2: siteData?.address2 || '',
    city: siteData?.city || '',
    state: siteData?.state || '',
    zipcode: siteData?.zipcode || '',
    recheckCycle: siteData?.recheckCycle || '',
  });

  const getCompanyList = async () => {
    const { isSuccess, data } = await apiCall(`${config.POST_LOGIN_API_URL}getCompanyList`, {}, {}, "GET");
    if (isSuccess) {
      setCompanyData(data?.data?.reverse());
    }
  };

  const getSitesContact = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POST_LOGIN_API_URL}getSitesContact`,
      {},
      { siteId: editData.id },
      "GET"
    );
    if (isSuccess) {
      setSiteUserData(data?.data?.sitesUserData);
      setSiteData(data?.data?.sitesData);
    } else {
      console.log("error");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSiteData({
      ...siteData,
      [name]: value,
    });
  };

  const changeCompany = (e) => {
    const value = e.target.value;
    setSiteData(prevAnswer => ({ ...prevAnswer, companyId: Number(value) }));


  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };
  const handleNextClick = () => {

    setCurrentPage("mainContact");

  };

  const handlePrevious = () => {
    setCurrentPage("editCompany");
  };

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const addressComponents = results[0].address_components;
      const getAddressComponent = (types) => {
        const component = addressComponents.find((comp) =>
          types.some((type) => comp.types.includes(type))
        );
        return component ? component.long_name : "";
      };

      const street =
        getAddressComponent(["street_number"]) +
        " " +
        getAddressComponent(["route"]);
      const city = getAddressComponent(["locality"]);
      const state = getAddressComponent(["administrative_area_level_1"]);
      const zipcode = getAddressComponent(["postal_code"]);

      setSiteData((prevAnswer) => ({
        ...prevAnswer,
        address: street,
        city,
        state,
        zipcode,
      }));
    } catch (error) {
      console.error("Error fetching address details: ", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    } else {
      getSitesContact();
      getCompanyList();
    }
  }, []);

  if (currentPage === "mainContact") {
    return (
      <SiteContact
        onPrevious={handlePrevious}
        siteData={siteData}
        siteUserData={siteUserData}
        setShowDetails={setShowDetails}
      />
    );
  }

  return (
    <div style={styles.container} className='pt-0' >
      <div className='mt-5'>
        <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#0050A4', paddingTop: '10px' }} className='mt-5 mb-0'>
          <div style={styles.companyDetailsTitle} className='ps-3 pt-1' >Edit Site Details</div>
        </div>
        <div style={{ padding: '15px', backgroundColor: '#e7f0f8', borderRadius: '4px' }}>
          <form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
              <div style={{ width: '48%' }}>
                <label style={{ display: 'block', color: '#0050A4', marginBottom: '5px', fontWeight: 'bold' }}>ARA ID</label>
                <input
                  type="text"
                  name="araId"
                  value={siteData.araId}
                  onChange={handleChange}
                  style={{ width: '100%', padding: '8px', color: '#0050A4', boxSizing: 'border-box', borderRadius: '4px', border: '1px solid #0050A4' }}
                />
              </div>

              <div style={{ width: '48%' }}>
                <label style={{ display: 'block', color: '#0050A4', marginBottom: '5px', fontWeight: 'bold' }}>Company</label>

                <select
                  value={siteData?.companyId || ''}
                  onChange={changeCompany}
                  id="company"
                  style={{ width: '100%', padding: '8px', color: '#0050A4', boxSizing: 'border-box', borderRadius: '4px', border: '1px solid #0050A4' }}

                >
                  <option value="" disabled>Select a company</option>
                  {companyData && companyData.length && companyData.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </select>

              </div>

            </div>
            <div style={{ display: 'flex', color: '#0050A4', justifyContent: 'space-between', marginBottom: '15px' }}>
              <div style={{ width: '33%' }}>
                <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Address</label>
                <PlacesAutocomplete
                  value={siteData.address}
                  onChange={(value) =>
                    setSiteData({ ...siteData, address: value })
                  }
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({ placeholder: "Search Street" })}
                        style={styles.input}
                      />
                      <div>
                        {loading ? <div>Loading...</div> : null}
                        {suggestions.map((suggestion, index) => {
                          const style = suggestion.active
                            ? { backgroundColor: "#a8dadc", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              key={index}
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>

              </div>
              <div style={{ width: '33%' }}>
                <label style={{ display: 'block', color: '#0050A4', marginBottom: '5px', fontWeight: 'bold' }}>Address 2</label>
                <input
                  type="text"
                  name="address2"
                  value={siteData.address2}
                  onChange={handleChange}
                  style={{ width: '100%', padding: '8px', color: '#0050A4', boxSizing: 'border-box', borderRadius: '4px', border: '1px solid #0050A4' }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', color: '#0050A4', justifyContent: 'space-between', marginBottom: '15px' }}>
              <div style={{ width: '24%' }}>
                <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>City</label>
                <input
                  type="text"
                  name="city"
                  value={siteData.city}
                  onChange={handleChange}
                  style={{ width: '100%', color: '#0050A4', padding: '8px', boxSizing: 'border-box', borderRadius: '4px', border: '1px solid #0050A4' }}
                />
              </div>
              <div style={{ width: '24%' }}>
                <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>State</label>
                <input
                  type="text"
                  name="state"
                  value={siteData.state}
                  onChange={handleChange}
                  style={{ width: '100%', color: '#0050A4', padding: '8px', boxSizing: 'border-box', borderRadius: '4px', border: '1px solid #0050A4' }}
                />
              </div>
              <div style={{ width: '24%' }}>
                <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Zipcode</label>
                <input
                  type="text"
                  name="zipcode"
                  value={siteData.zipcode}
                  onChange={handleChange}
                  style={{ width: '100%', padding: '8px', color: '#0050A4', boxSizing: 'border-box', borderRadius: '4px', border: '1px solid #0050A4' }}
                />
              </div>
              <div style={{ width: '24%' }}>
                <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Recheck Cycle</label>
                <input
                  type="text"
                  name="recheckCycle"
                  value={siteData.recheckCycle}
                  onChange={handleChange}
                  style={{ width: '100%', padding: '8px', color: '#0050A4', boxSizing: 'border-box', borderRadius: '4px', border: '1px solid #0050A4' }}
                />
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}>
              <button type="button" onClick={handleNextClick} style={styles.nextButton}>Next</button>

            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditSite;
const styles = {
  container: {
    padding: '20px',
    position: 'relative',
  },
  title: {
    fontSize: '32px',
    color: '#0050A4',
    marginBottom: '20px',
  },
  exportButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '5px 20px',
    backgroundColor: '#fff',
    fontSize: '16px',
    border: '1px solid #0050A4',
    borderRadius: '5px',
    color: '#0050A4',
    cursor: 'pointer',
  },
  companyDetails: {
    backgroundColor: '#E2EDF9',
    padding: '20px',
    marginTop: '0px',
    borderRadius: '5px',
  },
  companyDetailsTitle: {
    backgroundColor: '#0050A4',
    fontSize: '20px',
    color: 'white',

    // padding:'20px',
    marginBottom: '15px',
  },
  formGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '20px',  // Gap between columns
    gridRowGap: '20px',  // Gap between rows
    marginTop: '0px',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: '5px',
    color: '#0050A4',
  },
  input: {
    padding: '10px',
    border: '1px solid #0050A4',
    borderRadius: '4px',
    color: '#0050A4',
  },
  nextButton: {
    marginTop: '20px',
    padding: '10px 22px',
    backgroundColor: '#0050A4',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    //  position: 'relative',
    // float: 'right',


  },
  fullcontainer: {
    backgroundColor: '#E2EDF9',
  },
  rowGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '20px',  // Gap between columns
    gridRowGap: '20px',  // Gap between rows
  },
  lenderRowGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '20px',  // Gap between columns
    gridRowGap: '20px',  // Gap between rows
  },
};